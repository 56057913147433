import React from 'react'
import { StaticsHeader } from '../../Components/header/StaticsHeader11'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { staff_get_countries, staff_validate } from '../../../Static_Services/Staff_Services'
import { Spinner } from 'react-bootstrap'
import Tooltips from '../../Components/CommanPages/Tooltips'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'

export const ClientRegistration = () => {
    const [usertype, setUsertype] = useState('me');
    const [countryList, setCountryList] = useState([]);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('w_clientregistration')) {
            setRegisterData(JSON.parse(localStorage.getItem('w_clientregistration')));
        }
    }, [])

  
    const [loder, setLoder] = useState(false);

    const [registerData, setRegisterData] = useState({ name: '', lastname: '',  email: '', country_code: '', mobile: '', password: '',singup_for:''})
    const [registerDataError, setRegisterDataError] = useState({ name: '', lastname: '',  email: '', country_code: '', mobile: '', password: '',})

    const inputHandler = (e)=>{
        let {name,value}=e.target;
        if(name){
            setRegisterData((pre)=>({...pre,[name]:value}));
            setRegisterDataError((pre)=>({...pre,[name]:''}));
        }
    }

    const onregister = async () => {

        let isValid =1;

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const mobileNumberPattern = /^(?:\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s*\d{3}-\d{4})$/;
        const PassPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.])[A-Za-z\d$@$!%*?&#.]{8,30}$/;

        if(!registerData.name){
            setRegisterDataError((pre)=>({...pre,name:'First Name is required'}));
            isValid=2;
        }
        if(!registerData.lastname){
            setRegisterDataError((pre)=>({...pre,lastname:'Last Name is required'}));
            isValid=3;
        }

        if(!registerData.email){ setRegisterDataError((pre)=>({...pre,email:'Email is required'})); isValid=4;}
        if(registerData.email && !emailPattern.test(registerData.email)){ setRegisterDataError((pre)=>({...pre,email:'Email must be a valid email address'})); isValid=4; }

        if(!registerData.password){ setRegisterDataError((pre)=>({...pre,password:'Password is required'}));  isValid=4;}
        if(registerData.password && !PassPattern.test(registerData.password)){ setRegisterDataError((pre)=>({...pre,password:'Password length must be  8 with at least one special character, one capital letter, one small letter and one number.'})); isValid=4; }
       

        if(registerData.mobile && !mobileNumberPattern.test(registerData.mobile)){ setRegisterDataError((pre)=>({...pre,mobile:'Phone number not valid'})); isValid=4; }

        if(!registerData.country_code){ setRegisterDataError((pre)=>({...pre,country_code:'Country Code is required'})); isValid=4;}
        
        if(!registerData.mobile){setRegisterDataError((pre)=>({...pre,mobile:'Phone is required'})); isValid=4;}

      
        if (isValid===1) {
            setLoder(true);
             registerData.singup_for = usertype;
            const detail = { "email": registerData.email, "mobile": registerData.mobile };
            const resp = await staff_validate(detail);
         
            if(resp){
                // successAlert(resp.data.message);
              localStorage.setItem('w_clientregistration',JSON.stringify(registerData));   
              navigate('/otherdetail',{ state: { registerDeatil:  registerData}})   ;
              setLoder(false);
            } else{
              setLoder(false);
            }
        } else {
            setLoder(false);
            return false;
        }
    }



    const getUserType = (event) => {
        setUsertype(event.target.value);
    }

    const getCountry = async () => {
        const resp = await staff_get_countries();
        if (resp) {
            let cd = '';
            let response = resp.data;
            response.data.map((el) => {
                if (el.name == 'United States') {
                    cd = el.name + ' (' + el.code + ')';
                }
            })
            setCountryList(response.data);
        }
    }

  

    useEffect(() => {
        getCountry();
    }, [])

    return (
        <>
        {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <StaticsHeader />

            <div class="light-bg2">

                {/* <!-- Login Content Section --> */}
                <div class="background-before">
                    <div class="d-flex align-items-center justify-content-center staff-register-height p-4 p-sm-0">
                        <div class="align-item-center text-center wow animated slideInDown">
                            <div class="login-box-owner white-bg box-shadow1 border-radius10 py-4 px-3">

                                <div class="login-header text-center px-5">
                                    <h3 class="title dark-color mb-4"> Sign Up!! </h3>
                                    <p class="brand-color3 font-18 px-4"> Please sign up to check your details... </p>
                                </div>
                                <div class="logn-form mb-2">
                                    <form
                                    //    [formGroup]="userregister"
                                    >
                                        <div class="form-group profile-gender mb-2">
                                            <p class="brand-color3 text-left font-14 mb-2"> Signing up for </p>
                                            <div class="profile-gender-button text-left">
                                                <input type='radio' id='Me' checked={usertype === 'me'} value="me" name='radio'
                                                    onChange={(e) => getUserType(e)}
                                                />
                                                <label for='Me'>Me</label>
{/* 
                                                <input type='radio' id='Family' name='radio' value="family" checked={usertype === 'family'}
                                                    onChange={(e) => getUserType(e)}
                                                />
                                                <label for='Family'>Family Member</label> */}
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <p class="brand-color3 text-left font-14 mb-2"> First Name </p>
                                            <input type="text" class="form-control-1 w-100" placeholder="Carl" name='name'
                                             value={registerData.name} onChange={inputHandler} />
                                             <small className='text-danger'>{registerDataError.name}</small>
                                        </div>
                                        <div class="form-group">
                                            <p class="brand-color3 text-left font-14 mb-2"> Last Name </p>
                                            <input type="text" class="form-control-1 w-100" placeholder="Oilver" name='lastname'
                                             value={registerData.lastname} onChange={inputHandler} />
                                            <small className='text-danger'>{registerDataError.lastname}</small>
                                        </div>
                                        <div class="form-group">
                                            <p class="brand-color3 text-left font-14 mb-2"> Email </p>
                                            <input type="email" class="form-control-1 w-100" placeholder="" name='email'
                                             value={registerData.email} onChange={inputHandler} />
                                            <small className='text-danger'>{registerDataError.email}</small>
                                        </div>
                                        <div class="form-group">
                                            <p class="brand-color3 text-left font-14 mb-2"> Country Code </p>
                                            <select class="form-control-1 w-100" placeholder="Carl" name='country_code'
                                             value={registerData.country_code} onChange={inputHandler}   >
                                                <option value="" disabled>Select Country</option>
                                                {countryList.length > 0 && countryList.map((code) => (
                                                    <option value={`${code.name} (${code.code})`}  >
                                                        {`${code.name}  (${code.code}) `}
                                                    </option>))}
                                            </select>
                                            <small className='text-danger'>{registerDataError.country_code}</small>
                                        </div>
                                        <div class="form-group">
                                            <p class="brand-color3 text-left font-14 mb-2"> Phone </p>
                                            <input type="number" class="form-control-1 w-100" placeholder="" name='mobile' maxLength={12}
                                             value={registerData.mobile} onChange={inputHandler} />
                                             <small className='text-danger'>{registerDataError.mobile}</small>
                                        </div>

                                        <div class="form-group mt-3 mb-1">
                                            <p class="brand-color3 text-left font-14 mb-2"> Password <Tooltips info={'Password at least 8 characters long ,1 Capital letter ,1 number ,1 special character'} /></p>
                                            <input id="password-field" type={show ? 'text' : "password"} class="form-control-1 w-100" name="password"
                                             value={registerData.password} onChange={inputHandler} />
                                            <span
                                                onClick={() => setShow(!show)}
                                            ><img src="clientassets/img/input_eye.png" class="fa fa-fw fa-eye field-icon toggle-password" toggle="#password-field" /></span>
                                            <small className='text-danger'>{registerDataError.password}</small>
                                        </div>
                                        <div class="py-3">
                                            <button type="button" class="btn btn-blue-grad text-uppercase w-100"
                                                onClick={() => onregister()}
                                            > Next </button>
                                        </div>
                                        <div class="text-center">
                                            <p class="font-18 brand-color3 mb-2"> Already a User? <span> <Link to="/signin" class="brand-color1"> Login </Link></span></p>
                                        </div>
                                        <div class="text-center">
                                            <Link to="/terms-conditions" class="brand-color1 font-14"> Terms & Conditions </Link>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

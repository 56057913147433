import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { studio_clover_generate_token } from '../../../../Studio-Services/Studio_Services';
import { successAlert } from '../../../../Static_Services/Alertmsg';

export default function GenerateToken() {
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();    

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const generateCloverToken = async (clovertoken) => {
        setLoader(true);
        const resp = await studio_clover_generate_token(clovertoken);
        if (resp) {
            setLoader(false);
            successAlert('Clover setup completed');
            navigate('/Home');
            return;
        }
    }

    useEffect(() => {
        const size = queryParams.size;
        if (size > 0) {
            const clovertoken = {
                merchant_id: queryParams.get('merchant_id'),
                employee_id: queryParams.get('employee_id'),
                client_id: queryParams.get('client_id'),
                code: queryParams.get('code')
            };
            generateCloverToken(clovertoken);
            return;
        }
    }, [queryParams]);

    return (
        <>
            {loader && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div>}
        </>
    )
}

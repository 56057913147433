import React from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

export const CardClassList = (props) => {
  const classcarddetails = props.pass ? props.pass : [];


  return (
    <>
      <div className="ClassCardListed">
        <Row>
          {classcarddetails.map((value, cardIndex) => (
            <Col lg={4} key={cardIndex}>
              <Link
                to={"/edit-class"}
                state={{ details: value }}
                className="listCardLink"
              >
                <Card className="classList-pass">
                  <div className="cardImg">
                    <Card.Img variant="top" src={value.memberShipImg} />
                  </div>
                  <Card.Body className="py-0">
                    <Card.Title style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px' }}>
                      {value.class_name.length > 20 ? `${value.class_name.substring(0, 20)}...` : value.class_name}
                    </Card.Title>

                    {/* <Card.Title>{value.class_name}</Card.Title> */}
                  </Card.Body>
                  <Card.Text>
                    <small>
                      {value && value.date
                        ? value.date
                        : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
                    </small>
                    <p className="product-green">{value.product}</p>
                  </Card.Text>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

import React, { useEffect, useState } from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Link, useNavigate } from 'react-router-dom'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { Modal, Spinner } from 'react-bootstrap'
import { API_cardGet, API_cart_list, API_clover_pay_checkout_single, API_remove_cart, API_update_cart, ExpiryCheck } from '../../../Static_Services/Staff_Services'
import { successAlert } from '../../../Static_Services/Alertmsg'

export const MyCart = () => {
  const [userInfo, setUserInfo] = useState();
  const navigate = useNavigate();
  const [loder, setLoder] = useState(false);
  const [cartItem, setCartItem] = useState([])
  const [checkoutStatus, setCheckoutStatus] = useState(true);
  const [paymentval, setPaymentval] = useState(false);
  const [savecard, setSavecard] = useState(0);
  const [cardType, setCardType] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [paymentCard, setPaymentCard] = useState();

  const [studioData, setStudioData] = useState();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('w_loginDetail'))) {
      setUserInfo(JSON.parse(localStorage.getItem('w_loginDetail')))
    }
    if (localStorage.getItem('w_BusinessDetail')) {
      setStudioData(JSON.parse(localStorage.getItem('w_BusinessDetail')));
    }
  }, []);



  const redirectUrl = (url, Id) => {
    navigate(url, { state: { Id: Id } });
  }

  const getSaveCardDetail = async (userID, studioID) => {
    setLoder(true);
    const detail = { 'userid': userID, 'business_id': studioID };
    const resp = await API_cardGet(detail);
    if (resp) {
      const response = resp.data;
      response.data.map(function (el) {
        el.checked = false;
      });
      setCustomerData(response.data);
      setLoder(false);

    } else {
      setLoder(false);
    }
  }

  const updateCount = (value, data) => {
    setLoder(true);
    if (value == '+') {
      data.quantity = parseInt(data.quantity) + 1;
      updateCartDetail(data);
    } else {
      if (data.quantity > 1) {
        data.quantity = parseInt(data.quantity) - 1;
        updateCartDetail(data);
      } else {
        RemoveCartDetail(data);
        return false;
      }
    }
  }

  const getCartDetail = async () => {
    setLoder(true);
    const detail = { pageid: '1' };
    const resp = await API_cart_list(detail);
    if (resp) {
      const response = resp.data;
      setCartItem(response.data);
      setLoder(false);
    } else {
      setCartItem([]);
      const m = '';
      localStorage.removeItem('w_CartValues');
      setLoder(false);
    }
  }

  const updateCartDetail = async (data) => {
    const detail = { cart_id: data.cart_id, quantity: data.quantity };
    const resp = await API_update_cart(detail);
    if (resp) {
      const response = resp.data;
      successAlert(response.message);
      getCartDetail();
    } else {
      getCartDetail();
    }
  }

  const RemoveCartDetail = async (data) => {
    let itemCount = 0;

    let tempObj = {};
    tempObj.total_item = "0";
    tempObj.total_amount = "0";
    tempObj.tax = "0";
    tempObj.tax1_rate = "0";
    tempObj.tax2_rate = "0";
    tempObj.grand_total = "0";

    const business = cartItem.business_details[0];
    let tempObjBusinessArray = [];
    let tempObjBusinessObj = {};
    tempObjBusinessObj.business_id = business.business_id;
    tempObjBusinessObj.business_name = business.business_name;
    tempObjBusinessObj.logo = business.logo;
    tempObjBusinessObj.isCloverPurchase = business.isCloverPurchase;
    tempObjBusinessObj.studio_message = business.studio_message;
    tempObjBusinessObj.currency = business.currency;
    tempObjBusinessObj.cart_details = [];
    tempObjBusinessArray.push(tempObjBusinessObj);
    itemCount = business.cart_details.length;
    tempObj.business_details = tempObjBusinessArray;
    setLoder(true);
    const detail = { remove_cart_type: '0', cart_id: data.cart_id };
    const resp = await API_remove_cart(detail);
    if (resp) {
      const response = resp.data;
      setLoder(false);
      successAlert(response.message);
      getCartDetail();
      if (itemCount == 1) {
        setCartItem(tempObj);
        setCheckoutStatus(false);
      } else {
        setLoder(false);
        // getCartDetail();
      }
    } else {
      setLoder(false);
    }
  }

  const savePayment = async (card) => {
    setLoder(true);
    const detail = {
      amount: parseFloat(cartItem.grand_total).toFixed(2),
      token: card.card_token,
      savecard: 0,
      card_id: card.card_id,
      business_id: cartItem.business_details[0].business_id,
      country_code: '',
    };

    const resp = await API_clover_pay_checkout_single(detail);
    if (resp) {
      const response = resp.data;
      successAlert(response.message);
      setPaymentval(false);
      getCartDetail();
      navigate('/my-purchase');
      // navigate('/my-home');
      setLoder(false);
    } else {
      setLoder(false);
    }
  }

  // Payment Card Code
  const [expiryDate, setExpiryDate] = useState();
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      if (inputValue.endsWith('/')) {
        setExpiryDate(inputValue.slice(0, -1));
      } else { setExpiryDate(inputValue); }
    } else {
      if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
        setExpiryDate(inputValue + '/');
      } else if (inputValue.length === 8) { return; } else { setExpiryDate(inputValue); }
    }
  };

  const [carderror, setCarderror] = useState({ name: "", crnumber: "", dates: "", cvv: "" });
  const [cardData, setCardData] = useState({ name: "", crnumber: "", dates: "", cvv: "" });
  const CardInputChange = (e) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/^\s+/g, '');
    setCardData((pre) => ({ ...pre, [name]: cleanedValue }));
    setCarderror((pre) => ({ ...pre, [name]: "" }));
  }



  const paymentHandler = async () => {
    let isValid = 1;
    let expiry_month = '';
    let expiry_year = '';
    let expired = '';

    if (expiryDate) {
      expired = ExpiryCheck(expiryDate);
    }
    if (expired) {
      expiry_month = expired[0];
      expiry_year = expired[1];
    } else {
      setCarderror((pre) => ({ ...pre, dates: "* Valid Till is required" }));
      isValid = 2;
    }

    if (!cardData.name) { setCarderror((pre) => ({ ...pre, name: "* Card Holder Name is required" })); isValid = 3; };
    if (!cardData.crnumber) {
      setCarderror((pre) => ({ ...pre, crnumber: "* Card Number is required" }))
      isValid = 4;
    };
    if (!cardData.cvv) {
      setCarderror((pre) => ({ ...pre, cvv: "* CVV is required" }));
      isValid = 5;
    };
    if (isValid === 1) {
      setLoder(true);
      const detail = {
        amount: parseFloat(cartItem.grand_total).toFixed(2),
        token: '',
        savecard: savecard,
        card_id: '',
        number: cardData.crnumber,
        expiry_month: expiry_month,
        expiry_year: expiry_year,
        cvd: cardData.cvv,
        business_id: cartItem.business_details[0].business_id,
        country_code: '',
        customer_name: cardData.name,
      };

      const resp = await API_clover_pay_checkout_single(detail);
      if (resp) {
        const response = resp.data;
        successAlert(response.message);
        setLoder(false);
        getCartDetail();
        navigate('/my-purchase');
        // navigate('/my-home');
      } else {
        setLoder(false);
      }

    } else {
      setLoder(false);
      return false;
    }
  }



  const SavecardHandeler = (event) => {
    if (event.target.checked == true) {
      setSavecard(1);
    } else {
      setSavecard(0);
    }
  }

  const getCardType = (event) => {
    setCardType(event.target.id);
    let cData = customerData;
    cData.map((el) => {
      if (el.card_id == cardType) {
        el.checked = event.target.checked;
      }
    });
    setCustomerData(cData)
  }

  const CashPayment = async () => {
    // let card = ReferenceDetail;
    const card = true;
    if (card.valid) {
      setLoder(true);
      const detail = {
        amount: parseFloat(cartItem.grand_total).toFixed(2),
        token: paymentCard.value.token,
        savecard: savecard,
        card_id: '',
        number: paymentCard.value.card_number,
        expiry_month: paymentCard.value.expiry_month,
        expiry_year: paymentCard.value.expiry_year,
        cvd: paymentCard.value.cvv_no,
        business_id: cartItem.business_details[0].business_id,
        country_code: '',
        customer_name: paymentCard.value.card_holder_name,
      };

      const resp = await API_clover_pay_checkout_single(detail);
      if (resp) {
        const response = resp.data;
        successAlert(response.message);
        setLoder(false);
        // ReferenceDetail.reset();
        getCartDetail();
        navigate('/my-home');
        setPaymentval(false);
      } else {
        setLoder(false);
      }
    }
  }

  useEffect(() => {
    getCartDetail();
  }, [])



  useEffect(() => {
    if (studioData && studioData?.business_id && userInfo && userInfo?.id) {
      const newUserId = userInfo?.id;
      const studioID = studioData?.business_id;
      getSaveCardDetail(newUserId, studioID);
    }
  }, [userInfo, studioData])





  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />
      <div className="body-grey">


        {/* <!-- My Studio Card Detials-Service Section --> */}
        <div className="studio-details">
          <div className="container">

            <div className="pb-5">
              <div className="row">
                <div className="col-12 text-left">
                  <h4 className="title black-color text-uppercase mt-5"> Items in your Cart </h4>
                </div>
              </div>
              {/* <!-- End of Heading --> */}

              <div className="s-d-appoint-block">
                <div className="row">
                  {cartItem.length != 0}
                  <div className="col-12 col-lg-6 mt-3 mt-lg-0" >
                    {cartItem.business_details && cartItem.business_details.map((ITEMS) => (
                      <div className="client-buy-input border-radius6 box-shadow1 white-bg p-4">
                        <div className="row pb-2">
                          <div className="col-3 col-sm-2">
                            <div className="cart-logo">
                              <img src={ITEMS.logo} className="img-fluid" />
                            </div>
                          </div>
                          {/* <!-- end of col-3 --> */}
                          <div className="col-9 col-sm-9 pl-0 pt-2">
                            <div className="text-left mt-1 mt-md-3 mt-lg-1">
                              <h4 className="title black-color font-w-100"> {ITEMS.business_name} </h4>
                            </div>
                          </div>
                          {/* <!-- end of col-10 --> */}
                        </div>
                        {/* <!-- end of row --> */}

                        <div className="row">
                          <div className="col-12 ">
                            {ITEMS.cart_details && ITEMS.cart_details.map((SubITEMS) => (
                              <div className="white-bg border-radius6 box-shadow1 p-4 mb-2 mt-2">
                                <div className="row mb-0">
                                  <div className="col-3 pr-0">
                                    <div className="border-radius6 box-shadow1 p-3">
                                      {SubITEMS.item_image != '' &&
                                        <img src={SubITEMS.item_image} className="img-fluid w-10" />}
                                      {SubITEMS.item_image == '' &&
                                        <img src="clientassets/img/logo_green_web.png" className="img-fluid w-10" />}
                                    </div>
                                  </div>
                                  {/* <!-- end of col-9 --> */}
                                  <div className="col-9">
                                    <div className="">
                                      <div className="row">
                                        <div className="col-10">
                                          <h5 className="title black-color mb-0"> {SubITEMS.item_name}</h5>

                                        </div>
                                        <div className="col-2 text-right ">
                                          <span className="delete d-inline-block border-radius6"
                                            onClick={() => RemoveCartDetail(SubITEMS)}
                                          >
                                            <a href="Javascript:void(0);"> <i className="fas fa-trash-alt"></i> </a> </span>

                                        </div>

                                      </div>
                                      {SubITEMS.item_decription.length <= 40 &&
                                        <p className="brand-color3 font-12 my-2"> {SubITEMS.item_decription} </p>}
                                      {SubITEMS.item_decription.length > 40 &&
                                        <p className="brand-color3 font-12 my-2"> {SubITEMS.item_decription}</p>}
                                      {SubITEMS.service_type == '1' &&
                                        <div className="row">
                                          <div className="col-6">
                                            <h5 className="brand-color3 mb-0">Valid For {SubITEMS.pass_validity} </h5>
                                          </div>
                                        </div>
                                      }

                                      <div className="row">
                                        <div className="col-12">
                                          {SubITEMS.pass_type_name === 'Recurring Membership' ?
                                            <h5 className="title brand-color3 mb-0"> Amount :
                                              {cartItem && cartItem.business_details[0].currency} {SubITEMS.full_price}
                                            </h5>
                                            :
                                            <h5 className="title brand-color3 mb-0"> Amount :
                                              {cartItem && cartItem.business_details[0].currency} {SubITEMS.amount}
                                            </h5>
                                          }
                                        </div>
                                        <div className="col-6">
                                          <h5 className="title brand-color3 mb-0"> Tax 1 :
                                            {cartItem && cartItem.business_details[0].currency} {SubITEMS.tax1_rate}</h5>
                                        </div>
                                        <div className="col-6">
                                          <h5 className="title brand-color3 mb-0"> Tax 2 :
                                            {cartItem && cartItem.business_details[0].currency}  {SubITEMS.tax2_rate} </h5>
                                        </div>
                                      </div>


                                    </div>
                                    <div className="row">
                                      {SubITEMS.service_type != '1' &&
                                        <div className="col-5 pt-2 ">
                                          <div id="input_div" className="counter-box d-flex align-items-center">
                                            <input type="button" value="+" id="plus"
                                              className="brand-color2 font-w-600 font-28 mr-1 functionplus"
                                              onClick={() => updateCount('+', SubITEMS)}
                                              style={{ fontFamily: "Arial" }} />

                                            <input type="text" id="count" className="inputWidth brand-color1 flex-fill" value={SubITEMS.quantity} name="ProductCount" />
                                            <input type="button" value="-" id="moins"
                                              className="brand-color2 font-w-600 font-28 mr-1 functionplus"
                                              onClick={() => updateCount('-', SubITEMS)}
                                              style={{ fontFamily: "Arial" }} />
                                          </div>
                                        </div>
                                      }
                                      <div className="col-7 pt-2">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {cartItem.length == 0 &&
                    <div className="col-12 col-lg-12 mt-4 mt-lg-0" >
                      <div className="client-buy-input border-radius6 box-shadow1 white-bg p-4">
                        <div className="row pb-2">
                          <div className="col-3 col-sm-2">
                          </div>
                          <div className="col-9 col-sm-9 pl-0 pt-2">
                            <div className="text-center mt-1 mt-md-3 mt-lg-1">
                              <h4 className="title black-color font-w-100"> No item added in cart </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {!paymentval && cartItem.length != 0 &&
                    <div className="col-12 col-lg-6 mt-5 mt-lg-0">
                      <div className="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
                        <div className="px-3 py-3 text-left light-bg border-t-lr-6">
                          <h5 className="title black-color font-w-100 text-uppercase font-16 mb-0"> payment details </h5>
                        </div>
                        <div className="px-3 pt-4 pb-2 border-bottom">
                          <div className="row mb-2">
                            <div className="col-6 text-left">
                              <p className="black-color font-w-100 font-18"> Price( {cartItem.total_item ? cartItem.total_item : ''} Item) </p>
                            </div>
                            <div className="col-6 text-right">
                              <p className="black-color font-w-100 font-18">
                                {cartItem.length != 0 && cartItem.business_details[0].currency}   {cartItem.total_amount ? cartItem.total_amount : ''}   </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 text-left">
                              <p className="black-color font-w-100 font-18"> Tax 1 </p>
                            </div>
                            <div className="col-6 text-right">
                              <p className="black-color font-w-100 font-18">
                                {cartItem.length != 0 && cartItem.business_details[0].currency}  {cartItem && cartItem.tax1_rate}  </p>
                            </div>
                            <div className="col-6 text-left">
                              <p className="black-color font-w-100 font-18"> Tax 2 </p>
                            </div>
                            <div className="col-6 text-right">
                              <p className="black-color font-w-100 font-18">
                                {cartItem.length != 0 && cartItem?.business_details[0]?.currency} {cartItem && cartItem.tax2_rate}  </p>
                            </div>
                          </div>
                        </div>

                        <div className="px-3 pt-4 pb-3 border-bottom">
                          <div className="row mb-2">
                            <div className="col-6 text-left">
                              <p className="title black-color font-w-100 font-18 mb-0"> Amount Payable </p>
                            </div>
                            <div className="col-6 text-right">
                              <p className="title black-color font-w-100 font-18 mb-0">
                                {cartItem.length != 0 && cartItem?.business_details[0]?.currency}  {cartItem && cartItem.grand_total} </p>
                            </div>
                          </div>
                        </div>

                        {cartItem.length != 0 && checkoutStatus &&
                          <div className="px-3 py-5 text-center">
                            {cartItem && cartItem?.business_details[0]?.isCloverPurchase &&
                              <button type="button" className="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5"
                                onClick={() => setPaymentval(true)}
                              > checkout
                              </button>}
                            {!cartItem.business_details[0].isCloverPurchase &&
                              <p className="black-color font-w-100 font-18 mb-0 mb-3" >
                                {cartItem?.business_details[0]?.studio_message}</p>}
                          </div>
                        }
                      </div>
                    </div>
                  }

                  {paymentval &&
                    <div className="col-12 col-lg-6 mt-5 mt-lg-0">
                      <div className="client-buy-process border-radius6 box-shadow1 white-bg">
                        <div className="p-3 text-left light-bg border-t-lr-6">
                          <h5 className="title black-color font-w-100 text-uppercase font-16 mb-0"> credit card / debit card </h5>
                        </div>
                        <div className="px-3 pt-4 pb-3 border-bottom">
                          <div className="row mb-2">
                            <div className="col-6 text-left">
                              <p className="title black-color font-w-100 font-18 mb-0"> Amount Payable </p>
                            </div>
                            <div className="col-6 text-right">
                              <p className="title black-color font-w-100 font-18 mb-0">
                                {cartItem && cartItem?.business_details[0]?.currency} {cartItem?.grand_total} </p>
                            </div>
                          </div>
                        </div>
                        {checkoutStatus &&
                          <div className="client-payment-input border-radius6 box-shadow1 white-bg p-4">
                            <div className="row">
                              {customerData && customerData.map((cardsave) => (
                                <div className="col-12 mb-3" >
                                  {cardsave.length != 0 &&
                                    <div className="client-card-img white-bg border-radius6 box-shadow1">
                                      <label className="select-card select-card-img select-card-cvv mb-2">
                                        {(cardsave.card_type == 'VISA' || cardsave.card_type == 'visa') &&
                                          <img src="clientassets/img/visa_card.png" className="img-fluid payment_img" />}
                                        {cardsave.card_type == 'MC' &&
                                          <img src="clientassets/img/master_card.png" className="img-fluid payment_img" />}
                                        {cardsave.card_type == 'AM' &&
                                          <img src="clientassets/img/american_express.png" className="img-fluid payment_img" />}
                                        <p className="mb-0 font-12 dark-color mt-2">{cardsave.number} </p>
                                        <h5 className="mb-0 font-12 dark-color mt-2">Valid Till(MM/YYYY) </h5>
                                        <p className="mb-0 font-12 dark-color mt-2">{cardsave.expiry_month} / {cardsave.expiry_year}
                                        </p>
                                        <input type="radio" checked={cardType == cardsave.card_id} name="radio"
                                          id={cardsave.card_id} value={cardsave.card_id}
                                          onChange={(e) => getCardType(e)}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                      {cardType == cardsave.card_id &&
                                        <form >
                                          <div className="text-center d-flex">
                                            <button type="button" className="btn btn-blue-grad w-100 text-uppercase m-3"
                                              onClick={() => savePayment(cardsave)}
                                            > pay Now </button>
                                          </div>
                                        </form>
                                      }
                                    </div>
                                  }
                                </div>
                              ))}
                              <div className="col-12 mb-3">
                                <div className="white-bg border-radius6 box-shadow1">
                                  <label className="select-card"> New Card
                                    <input type="radio" checked={cardType == 'new'} name="radio" id="new" value="new" onChange={(e) => getCardType(e)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {cardType == 'new' &&
                          <form>
                            <div className="row py-4 px-3">
                              {/* Card Name */}
                              <div className="col-12 col-sm-6">
                                <div className="form-group">
                                  <p className="brand-color3 text-left font-14 mb-2"> Name On Card </p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    name='name'
                                    placeholder="Sophie Joe"
                                    value={cardData.name}
                                    onChange={CardInputChange}
                                  />
                                  <small className="text-danger">{carderror.name}</small>
                                </div>
                              </div>

                              {/* <!-- Card Number --> */}
                              <div className="col-12 col-sm-6">
                                <div className="form-group client-buy-input-cardno">
                                  <p className="brand-color3 text-left font-14 mb-2"> Card Number </p>
                                  <input
                                    type="text"
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder="4121 4411 3131 8554"
                                    name='crnumber'
                                    value={cardData.crnumber}
                                    onChange={CardInputChange}
                                    maxLength={19}
                                  />
                                  <small className="text-danger">{carderror.crnumber}</small>
                                </div>
                              </div>

                              {/* <!-- Exp... Dates --> */}
                              <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                <div className="form-group">
                                  <p className="brand-color3 text-left font-14 mb-2"> Valid Till </p>
                                  <input
                                    type="text"
                                    name={'dates'}
                                    className="form-control"
                                    placeholder="MM/YYYY"
                                    value={expiryDate}
                                    onChange={handleInputChange}
                                    pattern="^[0-9]{2}/[0-9]{4}"
                                    autoComplete="new-password"
                                    lable={"Valid Through"}
                                  />
                                  <small className="text-danger">{carderror.dates}</small>
                                </div>
                              </div>
                              {/* <!-- CVV  --> */}
                              <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                <div className="form-group">
                                  <p className="brand-color3 text-left font-14 mb-2"> CVV </p>
                                  <input
                                    type="password"
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder="eg. 201"
                                    name='cvv'
                                    value={cardData.cvv}
                                    onChange={CardInputChange}
                                    maxLength={3}
                                  />
                                  <small className="text-danger">{carderror.cvv}</small>
                                </div>
                              </div>
                              <div className="col-12">
                                <input type="checkbox" checked={savecard === 1} onChange={(e) => SavecardHandeler(e)} /> Save Card
                              </div>
                              <div className="text-center d-flex">
                                <button type="button" className="btn btn-blue-grad w-100 text-uppercase m-3"
                                  onClick={() => paymentHandler()}
                                > Proceed to pay </button>
                              </div>
                            </div>
                          </form>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* <!-- CHECKOUT MODAL POPUP --> */}
        <Modal show={false} className="modal fade" id="clientCheckOut" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body border-radius6 box-shadow1 p-5 white-bg">
                <div className="border-radius6 box-shadow-blue blue-gradient py-5 px-4 text-center">
                  <img src="clientassets/img/logo_white.png" className="img-fluid" />
                  <h4 className="title white-color text-uppercase my-4 font-w-600"> select checkout <br /> option </h4>
                  <button data-dismiss="modal" type="button" className="btn btn-white2 text-uppercase w-100 mb-4"
                    onClick={() => setPaymentval(true)}
                  > pay now </button>
                  <button data-dismiss="modal" type="button" className="btn btn-white2 text-uppercase w-100"> pay on pick up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* <!-- END OF CHECKOUT MODAL POPUP --> */}
      </div>
      <Innerfooter />
    </>
  )
}

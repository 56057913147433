import React from 'react'
import { useState } from 'react';
import { Row, Col, Card } from "react-bootstrap";

export const SheduleappointmentStep2 = ({ cardDetail, setStep, onClick }) => {
    

    return (
        <>
     
            <div className="section-heading statbox-heading p-3 text-left statbox m-3" style={{ minHeight: "0px" }}>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group profile-gender mb-0 mt-1">
                            <div className="service-button">
                                <div className="row">
                                    {/* <h1>STEP 2</h1> */}
                                    <h4 className="title black-color mb-0 ml-3">Service Chosen : {cardDetail.sData.service_name} </h4></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 text-right">
                        <button type="button" className="btn btn-blue-grad px-2 text-uppercase" onClick={() => setStep(0)}>Back </button>
                    </div>
                </div>
            </div>
            <div className="home-staff m-3">
                <div className="home-staff-details">
                    <div className="row">
                        {cardDetail && cardDetail.iData && cardDetail.iData.map((e) => (
                            <div className="col-12 col-md-6 col-lg-6 mb-3">
                                <div className="statbox p-4">
                                    <div className="row">
                                        <div className="col-12 col-sm-4 mb-4 mb-lg-0">
                                            <div className="service-img" style={{ width: "100px" }}><img className="img-fluid" src={e.profile} /></div>
                                        </div>
                                        <div className="col-12 col-sm-8">
                                            <div>
                                                <h5 className="title black-color mb-2">{e.name} {e.lastname}</h5>
                                                {/* <p className="black-color font-16 mb-1"> Experience: {e.experience} Years </p> */}
                                                <p className="black-color font-16 mb-1"> Appointment Fees : $ {cardDetail.sData.amount}</p>
                                                <p className="black-color font-16 mb-0"> Duration of Service : <span className="brand-color3 font-16"> {cardDetail.sData.duration} Min.</span></p>
                                            </div>
                                            <div className="home-service-offered-btn mt-3">
                                                <button type="button" className="btn btn-blue-grad px-4 text-uppercase" onClick={()=>onClick(e)}> Book </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useRef, useState } from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { staff_transaction_list } from '../../../Static_Services/Staff_Services';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const MyTransaction = () => {
    const [pagecount, setPagecount] = useState(1);
    const [transactionDetail, setTransactionDetail] = useState([]);
    const [loder, setLoder] = useState(false);
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState();



    useEffect(() => {
        if (location.pathname) {
            setCurrentUrl(location.pathname);
        }
    }, [location.pathname])

    useEffect(() => {
        if (currentUrl == '/my-transaction') {
            getTransactionDetail();
        } else {
            getPayoutDetail();
        }
    }, [currentUrl])

    const getPayoutDetail = () => {
        setLoder(false);
    }

    const getTransactionDetail = async () => {
        var detail = { 'pageid': pagecount };
        setLoder(true);
        const resp = await staff_transaction_list(detail);
        if (resp) {
            setLoder(false);
            const response = resp.data;
            if (response.data.length != 0) {
                response.data.map((el) => {
                    // Date Conversion UTC
                    el.transaction_date_new = moment.unix(el.transaction_date).format('MMM DD YYYY');
                    el.time = moment.unix(el.transaction_date).format('hh mm A');
                })
            }
            if (pagecount == 1) {
                setTransactionDetail(response.data);
            } else {
                setTransactionDetail((transactionDetail) => transactionDetail.concat(response.data));
            }
            setLoder(false);
        } else {
            if (pagecount == 1) {
                setTransactionDetail([]);
                setLoder(false);
            }
            setLoder(false);
        }
    }

    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />
            <div className="body-grey">
                <div class="client-payment">
                    <div class="container">
                        <div class="py-5">
                            <div class="row">
                                {currentUrl == '/my-payout' &&
                                    <div class="col-12 text-left">
                                        <h4 class="title black-color text-uppercase"> My Payout </h4>
                                    </div>
                                }
                                {currentUrl == '/my-transaction' &&
                                    <div class="col-12 text-left" >
                                        <h4 class="title black-color text-uppercase"> My Transactions </h4>
                                    </div>}
                            </div>
                            {/* <!-- End of Heading --> */}

                            {currentUrl == '/my-transaction' &&
                                <div class="row" >
                                    <div class="col-12 col-lg-12 mt-5 mt-lg-0 px-0">
                                        {transactionDetail.length != 0 &&
                                            <div class="client-buy-process border-radius6 box-shadow1 white-bg"  >
                                                <div 
                                                // style={{ height: '300px', overflowY: 'scroll' }}
                                                //  ref={containerRef} 
                                                >
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead>
                                                                <th>Transaction Id</th>
                                                                <th>Studio Name</th>
                                                                <th>Payment Mode</th>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Refrence Id</th>
                                                            </thead>
                                                            {transactionDetail.map((items) => (
                                                                <tr>
                                                                    {items.trx_id && <td style={{textAlign:'left'}}> {items.trx_id} </td>}
                                                                    {!items.trx_id && <td style={{textAlign:'left'}}> -- </td>}
                                                                    <td style={{textAlign:'left'}}> {items.business_name ? items.business_name : ""}</td>
                                                                    {items.payment_method && <td style={{textAlign:'left'}}> {items.payment_method =="Online" ? "Credit/Debit Card" :items.payment_method} </td>}
                                                                    {!items.payment_method && <td style={{textAlign:'left'}}> -- </td>}
                                                                    <td style={{textAlign:'left'}}> {items.transaction_date_new}  ,  {items.time} </td>
                                                                    <td style={{textAlign:'left'}}> {items.amount} </td>
                                                                    {items.reference_payment_id && <td style={{textAlign:'left'}}> {items.reference_payment_id} </td>}
                                                                    {!items.reference_payment_id && <td style={{textAlign:'left'}}> -- </td >}
                                                                </tr >
                                                            ))}
                                                        </table >
                                                    </div >
                                                </div >
                                            </div >
                                        }
                                        {transactionDetail.length == 0 &&
                                            <div class="client-buy-process border-radius6">
                                                <h3 class="text-center">No Transaction Found</h3>
                                            </div >
                                        }

                                    </div >
                                </div >
                            }
                            {/* < !--end of s - d - appoint-- > */}
                            {currentUrl == '/my-payout' &&
                                <div class="row">
                                    <div class="col-12 col-lg-12 mt-5 mt-lg-0 px-0">
                                        {transactionDetail.length != 0 &&
                                            <div class="client-buy-process border-radius6 box-shadow1 white-bg">
                                                <div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead>
                                                                <th>Payment For  </th>
                                                                <th>Refrence Id</th>
                                                                <th>Payment Mode</th>
                                                                <th>Transaction Id</th>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                            </thead>
                                                            {transactionDetail.map((items) => (
                                                                <tr>
                                                                    <td class="text-capitalize"> {items.name + ' '}   {items.lastname} </td>
                                                                    {items.reference_payment_id &&
                                                                        <td> {items.reference_payment_id} </td>}
                                                                    {!items.reference_payment_id && <td> -- </td>}
                                                                    {items.payment_mode &&
                                                                        <td> {items.payment_mode} </td>}
                                                                    {!items.payment_mode && <td> -- </td >}
                                                                    {items.trx_id && <td>   {items.trx_id}  </td >}
                                                                    {!items.trx_id && <td> -- </td >}
                                                                    <td > {items.transaction_date_new} </td>
                                                                    <td> {items.amount} </td>
                                                                </tr >
                                                            ))}
                                                        </table >
                                                    </div >
                                                </div >
                                            </div >
                                        }
                                        {transactionDetail.length == 0 &&
                                            <div class="client-buy-process border-radius6">
                                                <h3 class="text-center">No Transaction Found</h3>
                                            </div >
                                        }
                                    </div >
                                </div >
                            }
                        </div >
                        {/* < !--end of container-- > */}
                    </div >
                    {/* < !--End of My Studio Card Detials - Service Section-- > */}
                </div >
            </div >
            <Innerfooter />
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';

export default function TableWithPagination({ data = [], itemsPerPage = 10, onPageChange }) {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const showPagination = data.length > itemsPerPage;

    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) return;
        setCurrentPage(pageNumber);
    };
    useEffect(() => {
        if (onPageChange) {
            const indexOfLastItem = currentPage * itemsPerPage;
            const indexOfFirstItem = indexOfLastItem - itemsPerPage;
            onPageChange(data.slice(indexOfFirstItem, indexOfLastItem));
        }
    }, [currentPage, data, itemsPerPage, onPageChange]);

    const getPageNumbers = () => {
        const pages = [];
        const maxAdjacent = 2; // Show 2 pages before/after current
        let startPage = Math.max(1, currentPage - maxAdjacent);
        let endPage = Math.min(totalPages, currentPage + maxAdjacent);

        // Always show first page
        if (startPage > 1) {
            pages.push(1);
            if (startPage > 2) {
                pages.push('...');
            }
        }

        // Middle pages
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        // Always show last page
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push('...');
            }
            pages.push(totalPages);
        }

        return pages;
    };

    if (!showPagination) return null;

    return (
        <div className="d-flex justify-content-end mt-3">
            <Pagination>
                <Pagination.First
                    onClick={() => paginate(1)}
                    disabled={currentPage === 1}
                />
                <Pagination.Prev
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                />

                {getPageNumbers().map((page, index) => (
                    page === '...' ? (
                        <Pagination.Ellipsis key={`ellipsis-${index}`} disabled />
                    ) : (
                        <Pagination.Item
                            key={page}
                            active={page === currentPage}
                            onClick={() => paginate(page)}
                        >
                            {page}
                        </Pagination.Item>
                    )
                ))}

                <Pagination.Next
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                />
                <Pagination.Last
                    onClick={() => paginate(totalPages)}
                    disabled={currentPage === totalPages}
                />
            </Pagination>
        </div>
    );
}
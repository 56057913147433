import React, { useEffect, useState } from 'react'
import { StaticsHeader } from '../../Components/header/StaticsHeader11'
import { Link, useNavigate } from 'react-router-dom'
import { staff_get_countries, staff_validate } from '../../../Static_Services/Staff_Services'
import { successAlert } from '../../../Static_Services/Alertmsg'
import Tooltips from '../../Components/CommanPages/Tooltips'

export const StaffRegistration = () => {
  const [country, setCountry] = useState([]);
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();

  const getCountry = async () => {
    const resp = await staff_get_countries();
    if (resp) {
      setCountry(resp.data.data);
    } else setCountry([]);
  }

  useEffect(() => {
    getCountry();
  }, [])


  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    email: "",
    country_code: '',
    mobile: '',
    password: '',
  })


  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const fData = new FormData(e.target); // Create a FormData object from the form
    const formValues = Object.fromEntries(fData.entries());

    const name = formValues.first_name;
    const lastname = formValues.last_name;
    const email = formValues.email;
    const country_code = formValues.country_code;
    const mobile = formValues.mobile;
    const password = formValues.password;

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    // const regex = /^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
    const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.])[A-Za-z\d$@$!%*?&#.]{8,30}/;

    if (!name) { setError((pre) => ({ ...pre, first_name: 'Required' })); isValid = 2 }
    if (!lastname) { setError((pre) => ({ ...pre, last_name: 'Required' })); isValid = 2 }
    if (!email) {
      setError((pre) => ({ ...pre, email: 'Required' }));
      isValid = 2
    } else if (email && !email.match(emailPattern)) {
      setError((pre) => ({ ...pre, email: 'Email must be a valid email address' }));
      isValid = 3
    }
    if (!password) {
      setError((pre) => ({ ...pre, password: 'Required' }));
      isValid = 3;
    } else if (password && !regex.test(password)) {
      setError((pre) => ({ ...pre, password: 'Password length must be  8 with at least one special character, one capital letter, one small letter and one number' }));
      isValid = 4;
    }

    if (!mobile) {
      setError((pre) => ({ ...pre, mobile: 'Required' }));
      isValid = 3;
    } else if (mobile && mobile.length < 10) {
      setError((pre) => ({ ...pre, mobile: 'Phone number not valid' }));
      isValid = 3;
    }

    if (isValid === 1) {
      const detail = { "email": email, "mobile": mobile };
      const resp = await staff_validate(detail);
      if (resp) {
        e.target.reset();
        const userdetail = {
          name: formValues.first_name,
          lastname: formValues.last_name,
          email: formValues.email,
          country_code: formValues.country_code,
          mobile: formValues.mobile,
          password: formValues.password,
        }
        successAlert(resp.data.message);
        localStorage.setItem('w_staffregistration', JSON.stringify(userdetail));
        navigate('/staff-otherdetail', { state: { registerDeatil: userdetail } });
      }
    }
  }
  const errorHandler = (e) => {
    const { name, value } = e.target;
    setError((pre) => ({ ...pre, [name]: '' }));
  }
  return (
    <>
      <StaticsHeader />
      <div className="light-bg2">
        {/* <!-- Login Content Section --> */}
        <div className="background-before">
          <div className="d-flex align-items-center justify-content-center staff-login-height p-4 p-sm-0">
            <div className="align-item-center text-center wow animated slideInDown">
              <div className="login-box-owner white-bg box-shadow1 border-radius10 py-4 px-3 mt-4">

                <div className="login-header text-center px-5">
                  <h3 className="title dark-color mb-4"> Sign Up!! </h3>
                  <p className="brand-color3 font-18 px-4"> Please sign up to check your details... </p>
                </div>
                {/* <!-- end of login-header --> */}
                <div className="logn-form mb-2">
                  <form onSubmit={submitHandler} autoComplete='off'>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> First Name </p>
                      <input type="text" className="form-control-1 w-100" placeholder="Carl" name='first_name' formControlName="name" onChange={errorHandler} />
                      <small className='text-danger'>{error.first_name}</small>

                    </div>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Last Name </p>
                      <input type="text" className="form-control-1 w-100" placeholder="Oilver" name='last_name' formControlName="lastname" onChange={errorHandler} />
                      <small className='text-danger'>{error.last_name}</small>

                    </div>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Email </p>
                      <input type="email" className="form-control-1 w-100" placeholder="" name='email' formControlName="email" onChange={errorHandler} />
                      <small className='text-danger'>{error.email}</small>

                    </div>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Country Code </p>
                      <select className="form-control-1 w-100" formControlName="country_code" name='country_code' >
                      <option value="" disabled>Select Country</option>
                        {country && country.length > 0 && country.map((code, index) => (
                          <option value={code.code} selected={index === 0}>{code.name}{code.code} </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        <div >Country Code is required</div>
                      </div>
                    </div>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Phone </p>

                      <input type="number" className="form-control-1 w-100 " maxLength={10} placeholder="" name='mobile' formControlName="mobile" onChange={errorHandler} />
                      <small className='text-danger'>{error.mobile}</small>

                    </div>

                    <div className="form-group mt-3 mb-1">
                      <p className="brand-color3 text-left font-14 mb-2"> Password <Tooltips info={'Password at least 8 characters long ,1 Capital letter ,1 number ,1 special character'} /> </p>
                      
                      <input id="password-field" type={showPass ? 'text' : 'password'} className="form-control-1 w-100" name="password" formControlName="password" onChange={errorHandler} />
                      <span onClick={() => setShowPass(!showPass)}><img src="clientassets/img/input_eye.png" className="fa fa-fw fa-eye field-icon toggle-password" toggle="#password-field" /></span>
                      <small className='text-danger'>{error.password}</small>
                    </div>
                    <div className="py-3">
                      <button className="btn btn-green-grad text-uppercase w-100" type="submit" >

                        Next </button>
                    </div>
                    <div className="text-center">
                      <p className="font-18 brand-color3 mb-2"> Already a User? <span> <Link to="/staff-signin" className="brand-color2" > Login </Link></span></p>
                    </div>
                    <div className="text-center">
                      <Link to="/terms-conditions" className="brand-color2 font-14"> Terms & Conditions</Link>
                    </div>
                  </form>
                </div>
                {/* <!-- end of logn-form --> */}

              </div>
              {/* <!-- end of login-box --> */}
            </div>
          </div>
          {/* <!-- end of d-flex --> */}
        </div>
        {/* <!-- End of About Content Section --> */}
      </div>
    </>
  )
}

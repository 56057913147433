import { useEffect, useState } from 'react';
import { delete_website_otherSection_API, update_website, update_website_otherSection_API, webinfovalidation } from '../../../../Studio-Services/Studio_Services';
import Swal from 'sweetalert2';
import logo from "../../../../Images/logo_white_web.png";
import Tooltips from '../../../../client-staff/Components/CommanPages/Tooltips';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from '../../../../Static_Services/Alertmsg';
import ColorModal from './ColorModal';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { validateFile } from '../../../../sharedComponents/MyHelperFunction';
import UpdateOthersFiles from './UpdateOthersFiles';


export const Web_Edit = ({ setLoder, setIsEdit }) => {

    const [webdata, setWebdata] = useState();

    const [showFiles, setShowFiles] = useState({ "show": false, "data": '' });

    const [fdata, setFdata] = useState({
        "id": "", "domain": "", "brimage1": "", "brtitle1": "", "brsubtitle1": "", "banner1FileType": "", "banner2FileType": '', "poster1": "", "poster2": "",
        "brimage2": "", "brtitle2": "", "brsubtitle2": "", "abimage": "", "abheading": "", "abstory": "", "banner_font_color1": "", "banner_font_color2": "", "banner_font_color_tagline1": "", "banner_font_color_tagline2": "",
        "showinstructor": false, "showclass": false, "showpass": false, "showservices": false, "showworkshop": false, "showproduct": false, "facebook_id": '', "insta_id": "", "fontfamily": "", "inst_header": "", "inst_description": "", "cls_discription": "", "pas_discription": "", "serv_discription": "", "wrk_discription": "", "prd_discription": "", "provider1": "", "discription1": "", "provider2": "", "discription2": "", "provider3": "", "discription3": "", "favicon": "", "header_fonts": "", "background_for_pop_windows": "", "borders_and_buttons": "", "button_text_color": "", "video_channel_url": "", "video_title": ""
        , "location_url": "", "showbnlogo1": "", "showbnlogo2": "", "percentage1": 0, "percentage2": 0, "tr_color1": "", "tr_color2": "", "is_tr_color1": "no", "is_tr_color2": "no", "class_title": "", "service_title": "", "workshop_title": "", "other_title_1": "", "other_title_2": "", "other_description_1": "", "other_description_2": "", "other_url_1": "", "other_url_2": "", 'section_1_Files': 0, "section_1_Data": [], "section_2_Files": 0, "section_2_Data": [], "button_name1": "", "button_name2": "",
        "other_title_1_font_family": "", "other_title_1_font_color": "", "other_description_1_font_family": "", "other_description_1_color_code": "", "other_title_2_font_family": "", "other_title_2_font_color": "", "other_description_2_font_family": "", "other_description_2_font_color": "", "showOtherSection2": false, "showOtherSection1": false, "show_gallery_button_1": false, "show_gallery_button_2": false
    })

    const [error, setError] = useState({
        "domain": "", "brimage1": "", "brtitle1": "", "brsubtitle1": "", "banner1FileType": "", "banner2FileType": "", "poster1": "", "poster2": "",
        "brimage2": "", "brtitle2": "", "brsubtitle2": "", "abimage": "", "abheading": "", "abstory": "", "facebook_id": "", "insta_id": "", "fontfamily": ""
    });

    const [gallery, setGallery] = useState({ "data_file": "", "data_type": "", 'image': "" });
    const [galleryError, setGalleryError] = useState({ "data_file": "", "data_type": "" });

    const showImageFileHandler = (data) => {
        setShowFiles((pre) => ({ ...pre, "show": true }));
        setShowFiles((pre) => ({ ...pre, "data": data }));
    }

    const webinformaition = async () => {
        setLoder(true);
        const resp = await webinfovalidation({ "id": 123 });
        if (resp.status === 1) {
            setLoder(false);
            const e = resp.data;
            let fd = { ...fdata };
            if (e) {
                fd.id = e.id; fd.domain = e.business_domain; fd.brtitle1 = e.brTitle1; fd.brsubtitle1 = e.brSubtitle1;
                fd.other_title_1 = e.other_title_1; fd.other_title_2 = e.other_title_2;
                fd.button_name1 = e.button_name1; fd.button_name2 = e.button_name2;
                fd.banner1FileType = e.banner1FileType; fd.banner2FileType = e.banner2FileType

                fd.other_description_1 = e.other_description_1; fd.other_description_2 = e.other_description_2;

                fd.other_title_1_font_family = e.other_title_1_font_family;
                fd.other_title_1_font_color = e.other_title_1_font_color;
                fd.other_description_1_font_family = e.other_description_1_font_family;
                fd.other_description_1_color_code = e.other_description_1_color_code;
                fd.other_title_2_font_family = e.other_title_2_font_family;
                fd.other_title_2_font_color = e.other_title_2_font_color;
                fd.other_description_2_font_family = e.other_description_2_font_family;
                fd.other_description_2_font_color = e.other_description_2_font_color;
                fd.showOtherSection1 = e.showOtherSection1 == "yes" ? true : false;
                fd.showOtherSection2 = e.showOtherSection2 == "yes" ? true : false;

                fd.show_gallery_button_1 = e.show_gallery_button_1 == "yes" ? true : false;
                fd.show_gallery_button_2 = e.show_gallery_button_2 == "yes" ? true : false;


                fd.other_url_1 = e.other_url_1; fd.other_url_2 = e.other_url_2;
                fd.brtitle2 = e.brTitle2; fd.brsubtitle2 = e.brSubtitle2;
                fd.banner_font_color1 = e.banner_font_color1;
                fd.banner_font_color_tagline1 = e.banner_tagline1;
                fd.banner_font_color2 = e.banner_font_color2;
                fd.banner_font_color_tagline2 = e.banner_tagline2;
                fd.abheading = e.abtitle; fd.abstory = e.abstory;
                fd.header_fonts = e.Header_Fonts; fd.background_for_pop_windows = e.Background_for_Pop_windows; fd.borders_and_buttons = e.Borders_and_Buttons;
                fd.button_text_color = e.button_text_color;
                fd.showinstructor = e.showInstructor == "yes" ? true : false;
                fd.showclass = e.showClass == "yes" ? true : false;
                fd.showpass = e.showPass == "yes" ? true : false;
                fd.showservices = e.showService == "yes" ? true : false;
                fd.showworkshop = e.showWorkshop == "yes" ? true : false;
                fd.showproduct = e.showProduct == "yes" ? true : false;
                fd.facebook_id = e.facebook_id; fd.insta_id = e.insta_id; fd.fontfamily = e.font_family; fd.inst_header = e.inst_header; fd.inst_description = e.inst_description; fd.cls_discription = e.cls_description; fd.pas_discription = e.pas_description; fd.serv_discription = e.ser_description; fd.wrk_discription = e.wrk_description; fd.prd_discription = e.prd_description;
                fd.video_title = e.video_title;
                fd.video_channel_url = e.video_channel_url;
                fd.showbnlogo2 = e.showbnlogo2;
                fd.showbnlogo1 = e.showbnlogo1;
                fd.location_url = e.location_url;
                if ((e.testimonial).length > 0) {
                    fd.provider1 = (e.testimonial)[0] && (e.testimonial)[0].provider;
                    fd.discription1 = (e.testimonial)[0] && (e.testimonial)[0].description;
                    fd.provider2 = (e.testimonial)[1] && (e.testimonial)[1].provider;
                    fd.discription2 = (e.testimonial)[1] && (e.testimonial)[1].description;
                    fd.provider3 = (e.testimonial)[2] && (e.testimonial)[2].provider;
                    fd.discription3 = (e.testimonial)[2] && (e.testimonial)[2].description;
                };

                let section_1_Files = 0;
                let section_1_Data = [];
                let section_2_Files = 0;
                let section_2_Data = [];


                if ((e.others).length > 0) {
                    if (Array.isArray(e.others)) {
                        let others_data1 = e.others.filter((item) => item.section_type === "section_1");
                        section_1_Files = others_data1.length;
                        section_1_Data = others_data1;
                    } else {
                        // Handle case where e.others is not an array or is undefined
                        section_1_Files = 0;
                        section_1_Data = [];
                    }


                    let others_data2 = e.others.filter((e) => e.section_type === "section_2");
                    section_2_Files = others_data2.length;
                    section_2_Data = others_data2;
                };

                fd.section_1_Files = section_1_Files;
                fd.section_1_Data = section_1_Data;
                fd.section_2_Files = section_2_Files;
                fd.section_2_Data = section_2_Data;

                fd.percentage1 = e.percentage1;
                fd.percentage2 = e.percentage2;
                fd.tr_color1 = e.tr_color1;
                fd.tr_color2 = e.tr_color2;
                fd.is_tr_color1 = e.is_tr_color1;
                fd.is_tr_color2 = e.is_tr_color2;


                fd.class_title = e?.class_title;
                fd.service_title = e?.service_title;
                fd.workshop_title = e?.workshop_title;
            }
            setFdata(fd);
            setWebdata(resp.data);

        } else {
            setLoder(false);
            setWebdata();
        }
        setLoder(false);
    }

    useEffect(() => { webinformaition(); }, []);



    const navigate = useNavigate();

    const changeHandler = (e) => {
        const { name, value } = e.target;

        setFdata((pre) => ({ ...pre, [name]: value }))
        setError((pre) => ({ ...pre, [name]: "" }));
        if (name === "is_tr_color1") {
            if (value === "no") {
                setFdata((pre) => ({ ...pre, "percentage1": 0 }));
                setFdata((pre) => ({ ...pre, "tr_color1": "" }))
            }
        }
        if (name === "is_tr_color2") {
            if (value === "no") {
                setFdata((pre) => ({ ...pre, "percentage2": 0 }));
                setFdata((pre) => ({ ...pre, "tr_color2": "" }));
            }
        }
    }


    const checkHandler = (e) => {
        const { name, checked } = e.target;
        setFdata((pre) => ({ ...pre, [name]: checked }));
    }


    const submitHandler = async (e) => {
        e.preventDefault();

        const { class_title, service_title, workshop_title } = fdata;

        let isValid = 1;
        // if (!fdata.brtitle1) { setError((pre) => ({ ...pre, "brtitle1": "Required *" })); isValid = 4; }
        // if (!fdata.brsubtitle1) { setError((pre) => ({ ...pre, "brsubtitle1": "Required *" })); isValid = 5; }
        // if (!fdata.brtitle2) { setError((pre) => ({ ...pre, "brtitle2": "Required *" })); isValid = 7; }
        // if (!fdata.brsubtitle2) { setError((pre) => ({ ...pre, "brsubtitle2": "Required *" })); isValid = 8; }
        if (!fdata.abheading) { setError((pre) => ({ ...pre, "abheading": "Required *" })); isValid = 10; }
        if (!fdata.abstory) { setError((pre) => ({ ...pre, "abstory": "Required *" })); isValid = 11; }
        if (!fdata.id) { errorAlert("Something Wrong"); return; }


        if (!fdata.banner1FileType) { setError((pre) => ({ ...pre, "banner1FileType": "Required *" })); isValid = 4; }
        if (!fdata.banner2FileType) { setError((pre) => ({ ...pre, "banner2FileType": "Required *" })); isValid = 4; }

        if (isValid === 1) {
            setLoder(true);
            let formData = new FormData();
            let myshowinstructor = "no";
            let myshowclass = "no";
            let myshowpass = "no";
            let myshowservices = "no";
            let myshowworkshop = "no";
            let myshowproduct = "no";
            let showOtherSection1 = "no";
            let showOtherSection2 = "no";
            let show_gallery_button_1 = "no";
            let show_gallery_button_2 = "no";


            if (fdata.showinstructor) { myshowinstructor = "yes" }
            if (fdata.showclass) { myshowclass = "yes" }
            if (fdata.showpass) { myshowpass = "yes" }
            if (fdata.showservices) { myshowservices = "yes" }
            if (fdata.showworkshop) { myshowworkshop = "yes" }
            if (fdata.showproduct) { myshowproduct = "yes" }

            if (fdata.showOtherSection1) { showOtherSection1 = "yes" }
            if (fdata.showOtherSection2) { showOtherSection2 = "yes" }

            if (fdata.show_gallery_button_1) { show_gallery_button_1 = "yes" }
            if (fdata.show_gallery_button_2) { show_gallery_button_2 = "yes" }


            const bri1 = e.target.brimage1.files[0];
            const bri2 = e.target.brimage2.files[0];


            let videoPoster1 = "";
            let videoPoster2 = "";
            if (e?.target?.poster1) {
                videoPoster1 = e.target.poster1.files[0];
            }
            if (e?.target?.poster2) {
                videoPoster2 = e.target.poster2.files[0];
            }


            const abi = e.target.abimage.files[0];
            const favicon = e.target.favicon.files[0];

            let testi = [];
            if (fdata) {
                testi.push({ "provider": fdata.provider1, "description": fdata.discription1 });
                testi.push({ "provider": fdata.provider2, "description": fdata.discription2 });
                testi.push({ "provider": fdata.provider3, "description": fdata.discription3 });
            }

            formData.append('videoPoster1', videoPoster1);
            formData.append('videoPoster2', videoPoster2);

            formData.append('id', fdata.id);
            formData.append('brtitle1', fdata.brtitle1);
            formData.append('brsubtitle1', fdata.brsubtitle1);
            formData.append('brtitle2', fdata.brtitle2);
            formData.append('brsubtitle2', fdata.brsubtitle2);
            formData.append('font_family', fdata.fontfamily);

            formData.append("abheading", fdata.abheading);
            formData.append("abstory", fdata.abstory);
            formData.append("showinstructor", myshowinstructor);
            formData.append("showclass", myshowclass);
            formData.append("showpass", myshowpass);
            formData.append("showservices", myshowservices);
            formData.append("showworkshop", myshowworkshop);
            formData.append("showproduct", myshowproduct);

            formData.append("inst_header", fdata.inst_header);
            formData.append("inst_description", fdata.inst_description);
            formData.append("cls_discription", fdata.cls_discription);
            formData.append("pas_discription", fdata.pas_discription);
            formData.append("serv_discription", fdata.serv_discription);
            formData.append("wrk_discription", fdata.wrk_discription);
            formData.append("prd_discription", fdata.prd_discription);
            formData.append('testimonial', JSON.stringify(testi));

            formData.append("facebook_id", fdata.facebook_id);
            formData.append("insta_id", fdata.insta_id);

            formData.append('brimage1', bri1);
            formData.append('brimage2', bri2);
            formData.append('abimage', abi);

            formData.append("video_channel_url", fdata.video_channel_url);
            formData.append("video_title", fdata.video_title);
            formData.append("location_url", fdata.location_url);

            formData.append("percentage1", fdata.percentage1);
            formData.append("percentage2", fdata.percentage2);
            formData.append("tr_color1", fdata.tr_color1);
            formData.append("tr_color2", fdata.tr_color2);
            formData.append("is_tr_color1", fdata.is_tr_color1);
            formData.append("is_tr_color2", fdata.is_tr_color2);


            formData.append('class_title', class_title);
            formData.append('service_title', service_title);
            formData.append('workshop_title', workshop_title);


            formData.append('other_title_1', fdata.other_title_1);
            formData.append('other_title_2', fdata.other_title_2);
            formData.append('other_description_1', fdata.other_description_1);
            formData.append('other_description_2', fdata.other_description_2);
            formData.append('other_url_1', fdata.other_url_1);
            formData.append('other_url_2', fdata.other_url_2);
            formData.append('button_name1', fdata?.button_name1);
            formData.append('button_name2', fdata?.button_name2);

            formData.append('banner1FileType', fdata.banner1FileType);
            formData.append('banner2FileType', fdata.banner2FileType);

            let sanitizedHeaderFonts = "";
            let sanitizedBackground_for_pop_windows = "";
            let sanitizedBorders_and_buttons = "";

            if (fdata.header_fonts) {
                sanitizedHeaderFonts = fdata.header_fonts.replace(/^#/, '');
                if (sanitizedHeaderFonts) { sanitizedHeaderFonts = `#${sanitizedHeaderFonts}`; }
            }
            if (fdata.background_for_pop_windows) {
                sanitizedBackground_for_pop_windows = fdata.background_for_pop_windows.replace(/^#/, '');
                if (sanitizedBackground_for_pop_windows) { sanitizedBackground_for_pop_windows = `#${sanitizedBackground_for_pop_windows}`; }
            }

            if (fdata.borders_and_buttons) {
                sanitizedBorders_and_buttons = fdata.borders_and_buttons.replace(/^#/, '');
                if (sanitizedBorders_and_buttons) { sanitizedBorders_and_buttons = `#${sanitizedBorders_and_buttons}`; }
            }

            formData.append('favicon', favicon);
            formData.append("header_fonts", sanitizedHeaderFonts);
            formData.append("background_for_pop_windows", sanitizedBackground_for_pop_windows);
            formData.append("borders_and_buttons", sanitizedBorders_and_buttons);
            formData.append('button_text_color', fdata.button_text_color);

            let sanitizedBorders_banner_font_color1 = "";
            let sanitizedBorders_banner_font_color2 = "";



            if (fdata.banner_font_color1) {
                sanitizedBorders_banner_font_color1 = fdata.banner_font_color1.replace(/^#/, '');
                if (sanitizedBorders_banner_font_color1) { sanitizedBorders_banner_font_color1 = `#${sanitizedBorders_banner_font_color1}`; }

            }
            if (fdata.banner_font_color2) {
                sanitizedBorders_banner_font_color2 = fdata.banner_font_color2.replace(/^#/, '');
                if (sanitizedBorders_banner_font_color2) { sanitizedBorders_banner_font_color2 = `#${sanitizedBorders_banner_font_color2}`; }
            }

            formData.append("banner_font_color1", sanitizedBorders_banner_font_color1);
            formData.append("banner_font_color2", sanitizedBorders_banner_font_color2);

            let st_banner_font_color_tagline1 = "";
            let st_banner_font_color_tagline2 = "";

            if (fdata.banner_font_color_tagline1) {
                st_banner_font_color_tagline1 = fdata.banner_font_color_tagline1.replace(/^#/, '');
                if (st_banner_font_color_tagline1) { st_banner_font_color_tagline1 = `#${st_banner_font_color_tagline1}`; }

            }
            if (fdata.banner_font_color_tagline2) {
                st_banner_font_color_tagline2 = fdata.banner_font_color_tagline2.replace(/^#/, '');
                if (st_banner_font_color_tagline2) { st_banner_font_color_tagline2 = `#${st_banner_font_color_tagline2}`; }
            }
            formData.append("banner1_tagline1", st_banner_font_color_tagline1);
            formData.append("banner2_tagline2", st_banner_font_color_tagline2);

            formData.append("showbnlogo1", fdata.showbnlogo1);
            formData.append("showbnlogo2", fdata.showbnlogo2);

            formData.append("other_title_1_font_family", fdata.other_title_1_font_family);
            formData.append("other_title_1_font_color", fdata.other_title_1_font_color);
            formData.append("other_description_1_font_family", fdata.other_description_1_font_family);
            formData.append("other_description_1_color_code", fdata.other_description_1_color_code);
            formData.append("other_title_2_font_family", fdata.other_title_2_font_family);
            formData.append("other_title_2_font_color", fdata.other_title_2_font_color);
            formData.append("other_description_2_font_family", fdata.other_description_2_font_family);
            formData.append("other_description_2_font_color", fdata.other_description_2_font_color);

            formData.append("showOtherSection1", showOtherSection1);
            formData.append("showOtherSection2", showOtherSection2);

            formData.append('show_gallery_button_1', show_gallery_button_1);
            formData.append('show_gallery_button_2', show_gallery_button_2);

            const resp = await update_website(formData);
            if (resp) {
                webinformaition();
                setLoder(false);
                Swal.fire({
                    html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
                    <h4 class="title text-uppercase text-center mt-4 textWhite">
                    SUCCESS
                    </h4>
                    <p class="text-center my-4 textcolor">
                    <small class="textWhite">
                    ${resp.data.message} </small></p> `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                })
                setIsEdit(false);
                // .then((result) => {
                //     navigate('/businessweb');
                // });
                e.target.reset();
                setLoder(false);
            } else
                setLoder(false);
        }
        setLoder(false);
    }

    const tinyhandler = (value, name) => {
        setFdata((pre) => ({ ...pre, [name]: value }))
        setError((pre) => ({ ...pre, [name]: "" }));
    }

    const [addMore, setAddMore] = useState({ show: false, type: "" })

    const addImageHandler = (type) => {
        setAddMore((pre) => ({ ...pre, show: true, type: type }));
    }


    const changeNewHandler = (e) => {
        const { name, value } = e.target;

        if (name === "data_type") {
            setGallery((pre) => ({ ...pre, [name]: value }));
            setGallery((pre) => ({ ...pre, 'data_file': '' }));
            setGallery((pre) => ({ ...pre, 'image': '' }));
        } else {
            const { data_type } = gallery;
            if (!data_type) { errorAlert("Please select type"); return false; }

            const selectedFile = e.target.files[0];


            if (data_type === "image") {

                if (selectedFile) {
                    const validation = validateFile(selectedFile);
                    if (!validation?.valid) {
                        setGallery((pre) => ({ ...pre, [name]: '' }));
                        errorAlert(validation?.message);
                    } else {
                        setGallery((pre) => ({ ...pre, [name]: selectedFile }));
                        setGallery((pre) => ({ ...pre, 'image': value }));
                    }
                }
            } else {
                // Allowed video file extensions
                const allowedExtensions = [".mp4", ".avi", ".mov", ".mkv", ".flv", ".webm"];
                const maxSizeInMB = 200;
                if (selectedFile) {
                    const fileExtension = selectedFile.name.slice(selectedFile.name.lastIndexOf(".")).toLowerCase();
                    // Check if file extension is valid
                    if (!allowedExtensions.includes(fileExtension)) {
                        errorAlert("Invalid video format. Allowed formats are: .mp4, .avi, .mov, .mkv, .flv, .webm");
                        setGallery((pre) => ({ ...pre, [name]: '' }))
                        return;
                    }
                    // Check if the file size is greater than the 200MB limit
                    const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to MB
                    if (fileSizeInMB > maxSizeInMB) {
                        errorAlert("File size exceeds the 200MB limit.");
                        setGallery((pre) => ({ ...pre, [name]: '' }));
                        return;
                    }
                    setGallery((pre) => ({ ...pre, [name]: selectedFile }));
                    setGallery((pre) => ({ ...pre, 'image': value }));
                }
            }
        }
    }

    const addNewFileHandler = async (sectionType) => {
        const { data_file, data_type } = gallery;
        let isValid = 0;
        if (!data_type) { setGalleryError((pre) => ({ ...pre, "data_type": "Required *" })); isValid = 1; };
        if (!data_file) { setGalleryError((pre) => ({ ...pre, "data_file": "Required *" })); isValid = 2; };
        if (isValid === 0) {
            setLoder(true);

            let payload = new FormData();
            payload.append('id', fdata.id);
            payload.append('data_type', data_type);
            payload.append('data_file', data_file);
            payload.append('sectionType', sectionType);

            try {
                const resp = await update_website_otherSection_API(payload);
                if (resp) {
                    setGallery((pre) => ({ ...pre, 'data_file': '' }));
                    setGallery((pre) => ({ ...pre, 'data_type': '' }));
                    setGallery((pre) => ({ ...pre, 'image': '' }));
                    setAddMore((pre) => ({ ...pre, 'show': false }));
                    setAddMore((pre) => ({ ...pre, 'type': "" }));
                    webinformaition();
                    Swal.fire({
                        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
                        <h4 class="title text-uppercase text-center mt-4 textWhite">
                        SUCCESS
                        </h4>
                        <p class="text-center my-4 textcolor">
                        <small class="textWhite">
                        ${resp.data.message} </small></p> `,
                        showClass: {
                            popup: 'custom-dialog-container',
                        },
                        customClass: {
                            confirmButton: 'custom-button custom-button-size modalbtn',
                        },
                    })
                }
            } catch (error) {
                console.log("@Error-------", error);
            } finally {
                setLoder(false);
            }
        }
    }


    return (
        <>
            <div className='row'>
                <form onSubmit={submitHandler} autoComplete='off'>
                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="domain" className="form-label">
                                <strong style={{ color: 'black' }}>  Enter Your Domain Name </strong>
                                <Tooltips info={'www.example.com'} />
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='domain'
                                value={fdata.domain}
                                disabled="true"
                                id="domain"
                                placeholder="www.example.com"
                            />
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="fontfamily" className="form-label">
                                <strong style={{ color: 'black' }}>   Select Font-Family </strong>
                            </label>
                            <select className="form-control" name="fontfamily" onChange={changeHandler} value={fdata.fontfamily} id="fontfamily">
                                <option value="" selected disabled style={{ fontFamily: 'Arial, sans-serif' }}>Select</option>
                                <option value="Arial, sans-serif" style={{ fontFamily: 'Arial, sans-serif' }}>Arial</option>
                                <option value="Verdana, sans-serif" style={{ fontFamily: 'Verdana, sans-serif' }}>Verdana</option>
                                <option value="Georgia, serif" style={{ fontFamily: 'Georgia, serif' }}>Georgia</option>
                                <option value="Oswald, sans-serif" style={{ fontFamily: 'Oswald light' }}>Oswald light</option>
                                <option value="Comfortaa, cursive" style={{ fontFamily: "Comfortaa, cursive" }}>Comfortaa</option>
                            </select>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <label htmlFor="favicon" className="form-label">
                                <strong style={{ color: 'black' }}> Business Logo </strong>
                                <Tooltips info={'abc.ico & Width:16px, Height:16px'} />
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                name='favicon'
                                id="favicon"
                                onChange={changeHandler}
                                placeholder="Select abc.ico"
                            />
                        </div>
                    </div>
                    <div className='row mb-4'><hr /></div>
                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="brtitle1" className="form-label">
                                <strong style={{ color: 'black' }}> Company Name </strong>
                                {/* <span style={{ color: 'red' }}>*</span> */}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='brtitle1'
                                value={fdata.brtitle1}
                                onChange={changeHandler}
                                id="brtitle1"
                                placeholder="enter Title"
                            />
                            <span className='error'>{error.brtitle1}</span>
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="brsubtitle1" className="form-label">
                                <strong style={{ color: 'black' }}> Tag Line 1 </strong>
                                {/* <span style={{ color: 'red' }}>*</span> */}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='brsubtitle1'
                                value={fdata.brsubtitle1}
                                onChange={changeHandler}
                                id="brsubtitle1"
                                placeholder="enter sub-title"
                            />
                            <span className='error'>{error.brsubtitle1}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="banner1FileType" className="form-label">
                                <strong style={{ color: 'black' }}> Select  Type Image/Video</strong>
                            </label>
                            <select className="form-control" name="banner1FileType" value={fdata?.banner1FileType} onChange={changeHandler} id="banner1FileType">
                                <option value="" selected disabled >Select</option>
                                <option value="image">Image</option>
                                <option value="video">Video</option>
                            </select>
                            <small style={{ color: "red" }}>{error?.banner1FileType}</small>
                        </div>
                        {fdata?.banner1FileType == "video" &&
                            <div className='col-md-4 mb-3'>
                                <label htmlFor="poster1" className="form-label">
                                    <strong style={{ color: 'black' }}> Add Banner-1 Videos Poster </strong>
                                </label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name='poster1'
                                    id="poster1"
                                    onChange={changeHandler}
                                    placeholder="Select Image"
                                    accept={'image'}
                                />
                                <span className='error'>{error?.poster1}</span>
                            </div>
                        }
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brimage1" className="form-label">
                                <strong style={{ color: 'black' }}> Add Banner-1 Videos/Image </strong>
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                name='brimage1'
                                id="brimage1"
                                onChange={changeHandler}
                                placeholder="Select Image"
                                accept={fdata?.banner1FileType == 'image' ? "image/*" : "video/*"}
                            />
                            <span className='error'>{error?.brimage1}</span>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-3 mb-3'>
                            <p>Hex Colors :</p>
                            <div className='row'>
                                <div className='d-flex'>
                                    <h4 className='pr-4'>
                                        <strong style={{ color: 'black' }}>
                                            Choose Your Colors
                                        </strong>
                                    </h4>
                                    <ColorModal />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="banner_font_color1" className="form-label">
                                <strong style={{ color: 'black' }}>Company Name Font Color </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata.banner_font_color1}
                                name='banner_font_color1'
                                onChange={changeHandler}
                                id="banner_font_color1"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="brsubtitle2" className="form-label">
                                <strong style={{ color: 'black' }}>Tag Line 1 Font Color</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata.banner_font_color_tagline1}
                                name='banner_font_color_tagline1'
                                onChange={changeHandler}
                                id="banner_font_color_tagline1"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="showbnlogo1" className="form-label">
                                <strong style={{ color: 'black' }}>   Show Banner 1 Logo </strong>
                            </label>
                            <select className="form-control" name="showbnlogo1" onChange={changeHandler} value={fdata.showbnlogo1} id="showbnlogo1">
                                <option value="" selected disabled >Select</option>
                                <option value="yes" >Yes</option>
                                <option value="no" >No</option>
                            </select>
                        </div>

                    </div>
                    <div className='row w-100 mb-3'><hr /></div>
                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="brtitle2" className="form-label">
                                <strong style={{ color: 'black' }}> Business Objective </strong>
                                {/* <span style={{ color: 'red' }}>*</span> */}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='brtitle2'
                                value={fdata.brtitle2}
                                onChange={changeHandler}
                                id="brtitle2"
                                placeholder="enter title"
                            />
                            <span className='error'>{error.brtitle2}</span>
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="brsubtitle2" className="form-label">
                                <strong style={{ color: 'black' }}>Tag Line 2 </strong>
                                {/* <span style={{ color: 'red' }}>*</span> */}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='brsubtitle2'
                                value={fdata.brsubtitle2}
                                onChange={changeHandler}
                                id="brsubtitle2"
                                placeholder="enter sub-title"
                            />
                            <span className='error'>{error.brsubtitle2}</span>
                        </div>
                    </div>


                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="banner2FileType" className="form-label">
                                <strong style={{ color: 'black' }}> Select Type Image/Video</strong>
                            </label>
                            <select className="form-control" name="banner2FileType" value={fdata?.banner2FileType} onChange={changeHandler} id="banner2FileType">
                                <option value="" selected disabled >Select</option>
                                <option value="image">Image</option>
                                <option value="video">Video</option>
                            </select>
                            <small style={{ color: "red" }}>{error?.banner2FileType}</small>
                        </div>
                        {fdata?.banner2FileType == 'video' &&
                            <div className='col-md-4 mb-3'>
                                <label htmlFor="poster2" className="form-label">
                                    <strong style={{ color: 'black' }}> Add Banner-2 Videos Poster </strong>
                                </label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name='poster2'
                                    id="poster2"
                                    onChange={changeHandler}
                                    placeholder="Select Image"
                                    accept={'image'}
                                />
                                <span className='error'>{error?.poster2}</span>
                            </div>
                        }
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brimage2" className="form-label">
                                <strong style={{ color: 'black' }}> Add Banner-2 Images/Videos </strong>
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                name='brimage2'
                                id="brimage2"
                                onChange={changeHandler}
                                placeholder="Select Image"
                                accept={fdata?.banner2FileType == 'image' ? "image/*" : "video/*"}
                            />
                            <span className='error'>{error?.brimage2}</span>
                        </div>
                    </div>



                    <div className='row'>
                        <div className='col-md-3 mb-3'>
                            <p>Hex Colors :</p>
                            <div className='row'>
                                <div className='d-flex'>
                                    <h4 className='pr-4'>
                                        <strong style={{ color: 'black' }}>
                                            Choose Your Colors
                                        </strong>
                                    </h4>
                                    <ColorModal />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="banner_font_color2" className="form-label">
                                <strong style={{ color: 'black' }}>Business Objective Font Color </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata.banner_font_color2}
                                name='banner_font_color2'
                                onChange={changeHandler}
                                id="banner_font_color2"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="brsubtitle2" className="form-label">
                                <strong style={{ color: 'black' }}>Tagline 2 Font Color </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata.banner_font_color_tagline2}
                                name='banner_font_color_tagline2'
                                onChange={changeHandler}
                                id="banner_font_color_tagline2"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="showbnlogo2" className="form-label">
                                <strong style={{ color: 'black' }}>   Show Banner 2 Logo </strong>
                            </label>
                            <select className="form-control" name="showbnlogo2" onChange={changeHandler} value={fdata.showbnlogo2} id="showbnlogo2">
                                <option value="" selected disabled >Select</option>
                                <option value="yes" >Yes</option>
                                <option value="no" >No</option>
                            </select>
                        </div>

                    </div>
                    <div className='row w-100 mb-3'><hr /></div>

                    <div className='row mt-3 mb-3'>
                        <div className='col-md-3 mb-3'>
                            <p>Transparent Block
                                <Tooltips info={'The Transparent Block will add a colour behind your banner wording so it is more legible. Pick a colour and level of transparency'} />
                            </p>
                            <div className='row'>
                                <div className='d-flex'>
                                    <h4 className='pr-4'>
                                        <strong style={{ color: 'black' }}>
                                            Choose Your Colors
                                        </strong>
                                    </h4>
                                    <ColorModal />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="is_tr_color1" className="form-label">
                                <strong style={{ color: 'black' }}>Is Transparent </strong>
                            </label>
                            <select className="form-control" name="is_tr_color1" onChange={changeHandler} value={fdata.is_tr_color1} id="showbnlogo1">
                                <option value="" selected disabled >Select</option>
                                <option value="yes" >Yes</option>
                                <option value="no" >No</option>
                            </select>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="tr_color1" className="form-label">
                                <strong style={{ color: 'black' }}>Transparency color </strong>
                            </label>
                            <input
                                type="text"
                                readOnly={fdata.is_tr_color1 == "no" ? true : false}
                                className="form-control"
                                value={fdata.tr_color1}
                                name='tr_color1'
                                onChange={changeHandler}
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="brsubtitle2" className="form-label">
                                <strong style={{ color: 'black' }}>Transparency percentage</strong>
                            </label>
                            <small className="range-value ml-3">{fdata.percentage1}%</small>
                            <input
                                type="range"
                                disabled={fdata.is_tr_color1 == "no" ? true : false}
                                min="0"
                                max="100"
                                className="form-range"
                                value={fdata.percentage1}
                                name='percentage1'
                                onChange={changeHandler}
                            />
                        </div>
                    </div>

                    {/* <div className='row w-100 mb-3'><hr /></div> */}

                    <div className='row'>
                        <div className='col-md-12'>
                            <hr />
                            <div className='d-flex mt-2'>
                                <h5 className='pr-4'>
                                    <strong style={{ color: 'black' }}>
                                        Change titles
                                    </strong>
                                </h5>
                            </div>

                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="class_title" className="form-label">
                                <strong style={{ color: 'black' }}> Classes </strong>
                                <Tooltips info={'Changing the title will not change the titles in the Warrior app, just your website'} />
                            </label>

                            <input
                                type="text"
                                className="form-control"
                                value={fdata?.class_title}
                                name='class_title'
                                onChange={changeHandler}
                                id="class_title"
                                placeholder="enter class titile"
                            />
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="service_title" className="form-label">
                                <strong style={{ color: 'black' }}> Services </strong>
                                <Tooltips info={'Changing the title will not change the titles in the Warrior app, just your website'} />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata?.service_title}
                                name='service_title'
                                onChange={changeHandler}
                                id="service_title"
                                placeholder="enter service title"
                            />
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brsubtitle2" className="form-label">
                                <strong style={{ color: 'black' }}> Workshops </strong>
                                <Tooltips info={'Changing the title will not change the titles in the Warrior app, just your website'} />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata?.workshop_title}
                                name='workshop_title'
                                onChange={changeHandler}
                                id="workshop_title"
                                placeholder="enter workshop title"
                            />
                        </div>
                        <div className='col-md-12'>
                            <hr />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="abimage" className="form-label">
                                <strong style={{ color: 'black' }}>  Select “About Us” Image </strong>
                                <Tooltips info={'Image Size 600 X 400'} />
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                name='abimage'
                                onChange={changeHandler}
                                id="abimage"
                                placeholder="Select Image"
                            />
                            <span className='error'>{error.abimage}</span>
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="abheading" className="form-label">
                                <strong style={{ color: 'black' }}>   “About Us” Header </strong>
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='abheading'
                                value={fdata.abheading}
                                onChange={changeHandler}
                                id="abheading"
                                placeholder="Header for “About Us” section"
                            />
                            <span className='error'>{error.abheading}</span>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label htmlFor="abstory" className="form-label">
                                <strong style={{ color: 'black' }}> “About Us” Story </strong>
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <textarea
                                class="form-control"
                                id="abstory"
                                name='abstory'
                                value={fdata.abstory}
                                onChange={changeHandler}
                                rows="3"
                            ></textarea>
                            <span className='error'>{error.abstory}</span>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="inst_header" className="form-label">
                                <strong style={{ color: 'black' }}>   Instructors / Staff Header </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='inst_header'
                                value={fdata.inst_header}
                                onChange={changeHandler}
                                id="inst_header"
                                placeholder="What title do you want for your title: Staff, Instructors, or Trainers?"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="inst_description" className="form-label">
                                <strong style={{ color: 'black' }}>  Instructor / Staff Description </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='inst_description'
                                value={fdata.inst_description}
                                onChange={changeHandler}
                                id="inst_description"
                                placeholder="Briefly describe your staff"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="cls_discription" className="form-label">
                                <strong style={{ color: 'black' }}>    Classes  </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='cls_discription'
                                value={fdata.cls_discription}
                                onChange={changeHandler}
                                id="cls_discription"
                                placeholder="Briefly describe your classes"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="pas_discription" className="form-label">
                                <strong style={{ color: 'black' }}> Passes </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='pas_discription'
                                value={fdata.pas_discription}
                                onChange={changeHandler}
                                id="pas_discription"
                                placeholder="Briefly describe your Passes"
                            />
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label htmlFor="serv_discription" className="form-label">
                                <strong style={{ color: 'black' }}> Services </strong> </label>
                            <input
                                type="text"
                                className="form-control"
                                name='serv_discription'
                                value={fdata.serv_discription}
                                onChange={changeHandler}
                                id="serv_discription"
                                placeholder="Briefly describe your services"
                            />
                            {/* <React_quill name="serv_discription" value={fdata.serv_discription}  placeholder="Briefly describe your services" onChange={tinyhandler}/> */}
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label htmlFor="wrk_discription" className="form-label"> <strong style={{ color: 'black' }}> Workshops </strong> </label>
                            <input
                                type="text"
                                className="form-control"
                                name='wrk_discription'
                                value={fdata.wrk_discription}
                                onChange={changeHandler}
                                id="wrk_discription"
                                placeholder="Briefly describe your workshops"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="prd_discription" className="form-label"><strong style={{ color: 'black' }}> Products </strong> </label>
                            <input
                                type="text"
                                className="form-control"
                                name='prd_discription'
                                value={fdata.prd_discription}
                                onChange={changeHandler}
                                id="prd_discription"
                                placeholder="Briefly describe your products"
                            />
                        </div>
                    </div>
                    <h4>Scrolling up to 3 testimonial statements</h4>

                    <div className='row'>
                        <small className="form-label">
                            <strong style={{ color: 'black' }}>
                                Testimonial 1
                            </strong>
                        </small>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='provider1'
                                value={fdata.provider1}
                                onChange={changeHandler}
                                id="provider1"
                                placeholder="Testimonial Provided By"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='discription1'
                                value={fdata.discription1}
                                onChange={changeHandler}
                                id="discription1"
                                placeholder="Testimonial Statement"
                            />
                        </div>
                        <small className="form-label">
                            <strong style={{ color: 'black' }}>
                                Testimonial 2
                            </strong>
                        </small>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='provider2'
                                value={fdata.provider2}
                                onChange={changeHandler}
                                id="provider2"
                                placeholder="Testimonial Provided By"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='discription2'
                                value={fdata.discription2}
                                onChange={changeHandler}
                                id="discription2"
                                placeholder="Testimonial Statement"
                            />
                        </div>
                        <small className="form-label">
                            <strong style={{ color: 'black' }}>
                                Testimonial 3
                            </strong>
                        </small>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='provider3'
                                value={fdata.provider3}
                                onChange={changeHandler}
                                id="provider3"
                                placeholder="Testimonial Provided By"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='discription3'
                                value={fdata.discription3}
                                onChange={changeHandler}
                                id="discription3"
                                placeholder="Testimonial Statement"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="facebook_id" className="form-label">
                                <strong style={{ color: 'black' }}> Facebook ID </strong></label>
                            <Tooltips info="enter only ID name (Do not include any type of URL)" />
                            <input
                                type="text"
                                className="form-control"
                                name='facebook_id'
                                value={fdata.facebook_id}
                                onChange={changeHandler}
                                id="facebook_id"
                                placeholder="warriorsms2K24"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="insta_id" className="form-label">
                                <strong style={{ color: 'black' }}>  Instagram ID </strong></label>
                            <Tooltips info="enter only ID name (Do not include any type of URL)" />
                            <input
                                type="text"
                                className="form-control"
                                name='insta_id'
                                value={fdata.insta_id}
                                onChange={changeHandler}
                                id="insta_id"
                                placeholder="warriorsms2K24"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="video_title" className="form-label">
                                <strong style={{ color: 'black' }}> Video Title </strong></label>
                            <Tooltips info="enter only name (Do not include any type of URL)" />
                            <input
                                type="text"
                                className="form-control"
                                name='video_title'
                                value={fdata.video_title}
                                onChange={changeHandler}
                                id="video_title"
                                placeholder="Youtube"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="video_channel_url" className="form-label">
                                <strong style={{ color: 'black' }}>  Video Channel Link </strong></label>
                            <Tooltips info="enter Complete URL" />
                            <input
                                type="text"
                                className="form-control"
                                name='video_channel_url'
                                value={fdata.video_channel_url}
                                onChange={changeHandler}
                                id="video_channel_url"
                                placeholder=""
                            />
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label htmlFor="location_url" className="form-label">
                                <strong style={{ color: 'black' }}> Location URL </strong></label>
                            <Tooltips info="enter Complete URL" />
                            <input
                                type="text"
                                className="form-control"
                                name='location_url'
                                value={fdata.location_url}
                                onChange={changeHandler}
                                id="location_url"
                                placeholder=""
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='row'>
                            <div className='d-flex'>
                                <h4 className='pr-4'><strong style={{ color: 'black' }}> Choose Your Colors</strong></h4>
                                <ColorModal />
                            </div>
                            <hr />
                            <p>Hex Colors :</p>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="header_fonts" className="form-label"><strong style={{ color: 'black' }}>  Header Fonts</strong></label>
                            <input
                                type="text"
                                className="form-control"
                                name='header_fonts'
                                value={fdata.header_fonts}
                                onChange={changeHandler}
                                id="header_fonts"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="background_for_pop_windows" className="form-label"> <strong style={{ color: 'black' }}> Background for Pop windows </strong></label>
                            <input
                                type="text"
                                className="form-control"
                                name='background_for_pop_windows'
                                value={fdata.background_for_pop_windows}
                                onChange={changeHandler}
                                id="background_for_pop_windows"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="borders_and_buttons" className="form-label"> <strong style={{ color: 'black' }}> Borders and Buttons</strong> </label>
                            <input
                                type="text"
                                className="form-control"
                                name='borders_and_buttons'
                                value={fdata.borders_and_buttons}
                                onChange={changeHandler}
                                id="borders_and_buttons"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="button_text_color" className="form-label"> <strong style={{ color: 'black' }}> Buttons Text Color </strong> </label>
                            <select className="form-control" name="button_text_color" value={fdata.button_text_color} onChange={changeHandler} id="button_text_color">
                                <option value="" disabled>Select</option>
                                <option value="#FFFFFF" >White</option>
                                <option value="#000000" >Black</option>
                            </select>
                        </div>
                    </div>


                    <div className='row mt-3'>
                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showinstructor'
                                    checked={fdata.showinstructor}
                                    onChange={checkHandler}
                                    id="showinstructor"
                                />
                                <label className="form-label" htmlFor="showinstructor">
                                    {/* Show Instructors data */}
                                    <strong style={{ color: 'black' }}>     Instructors / Staff Header</strong>
                                </label>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showclass'
                                    checked={fdata.showclass}
                                    onChange={checkHandler}
                                    id="showclass"
                                />
                                <label className="form-label" htmlFor="showclass">
                                    <strong style={{ color: 'black' }}>    Show Classes data </strong>
                                </label>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showpass'
                                    checked={fdata.showpass}
                                    onChange={checkHandler}
                                    id="showpass"
                                />
                                <label className="form-label" htmlFor="showpass">
                                    <strong style={{ color: 'black' }}>    Show Passes data </strong>
                                </label>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showservices'
                                    checked={fdata.showservices}
                                    onChange={checkHandler}
                                    id="showservices"
                                />
                                <label className="form-label" htmlFor="showservices">
                                    <strong style={{ color: 'black' }}>   Show Services data </strong>
                                </label>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showworkshop'
                                    checked={fdata.showworkshop}
                                    onChange={checkHandler}
                                    id="showworkshop"
                                />
                                <label className="form-label" htmlFor="showworkshop">
                                    <strong style={{ color: 'black' }}>   Show Workshops data </strong>
                                </label>
                            </div>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showproduct'
                                    checked={fdata.showproduct}
                                    onChange={checkHandler}
                                    id="showproduct"
                                />
                                <label className="form-label" htmlFor="showproduct">
                                    <strong style={{ color: 'black' }}>     Show Products data </strong>
                                </label>
                            </div>
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <hr />
                            <div className='d-flex mt-2'>
                                <h5 className='pr-4'>
                                    <strong style={{ color: 'black' }}>
                                        Other Sections
                                    </strong>
                                </h5>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='row'>
                                <lable className="form-label d-flex" style={{ justifyContent: 'end' }}>
                                    <strong style={{ color: 'black', marginRight: "10px" }}> Show Section - 1 </strong>
                                    <div className='form-check'>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='showOtherSection1'
                                            checked={fdata.showOtherSection1}
                                            onChange={checkHandler}
                                            id="showOtherSection1"
                                        />
                                    </div>
                                </lable>
                                <div className='col-md-12 mb-2 mt-2'>
                                    <hr />
                                </div>
                            </div>
                        </div>


                        <div className='col-md-4 mb-3'>
                            <label htmlFor="other_title_1" className="form-label">
                                <strong style={{ color: 'black' }}> Choose a title </strong>
                                {/* <Tooltips info={'Changing the title will not change the titles in the Warrior app, just your website'} /> */}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata?.other_title_1}
                                name='other_title_1'
                                onChange={changeHandler}
                                id="other_title_1"
                                placeholder="enter other titile"
                            />
                        </div>

                        {/*  Section -----------1  Title  ********** */}



                        <div className='col-md-4 mb-3'>
                            <label htmlFor="other_title_1_font_family" className="form-label">
                                <strong style={{ color: 'black' }}> Apply Custom Font</strong>
                            </label>
                            <select className="form-control" name="other_title_1_font_family" onChange={changeHandler} value={fdata.other_title_1_font_family} id="other_title_1_font_family">
                                <option value="" selected disabled style={{ fontFamily: 'Arial, sans-serif' }}>Select</option>
                                <option value="Arial, sans-serif" style={{ fontFamily: 'Arial, sans-serif' }}>Arial</option>
                                <option value="Verdana, sans-serif" style={{ fontFamily: 'Verdana, sans-serif' }}>Verdana</option>
                                <option value="Georgia, serif" style={{ fontFamily: 'Georgia, serif' }}>Georgia</option>
                                <option value="Oswald, sans-serif" style={{ fontFamily: 'Oswald light' }}>Oswald light</option>
                                <option value="Comfortaa, cursive" style={{ fontFamily: "Comfortaa, cursive" }}>Comfortaa</option>
                            </select>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                <ColorModal />
                                <label htmlFor="other_title_1_font_color" className="form-label"><strong style={{ color: 'black' }}>  Apply Custom Color</strong></label>

                            </div>
                            <input
                                type="text"
                                className="form-control"
                                name='other_title_1_font_color'
                                value={fdata.other_title_1_font_color}
                                onChange={changeHandler}
                                id="other_title_1_font_color"
                                placeholder="211B16"
                            />
                        </div>


                        {/*  Section -----------1  Description  ********** */}

                        <div className='col-md-4 mb-3'>
                            <label htmlFor="other_description_1" className="form-label">
                                <strong style={{ color: 'black' }}> Add a description below </strong>
                                {/* <Tooltips info={'Changing the title will not change the titles in the Warrior app, just your website'} /> */}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata?.other_description_1}
                                name='other_description_1'
                                onChange={changeHandler}
                                id="other_description_1"
                                placeholder="enter other description"
                            />
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="other_description_1_font_family" className="form-label">
                                <strong style={{ color: 'black' }}>   Apply Custom Font </strong>
                            </label>
                            <select className="form-control" name="other_description_1_font_family" onChange={changeHandler} value={fdata.other_description_1_font_family} id="other_description_1_font_family">
                                <option value="" selected disabled style={{ fontFamily: 'Arial, sans-serif' }}>Select</option>
                                <option value="Arial, sans-serif" style={{ fontFamily: 'Arial, sans-serif' }}>Arial</option>
                                <option value="Verdana, sans-serif" style={{ fontFamily: 'Verdana, sans-serif' }}>Verdana</option>
                                <option value="Georgia, serif" style={{ fontFamily: 'Georgia, serif' }}>Georgia</option>
                                <option value="Oswald, sans-serif" style={{ fontFamily: 'Oswald light' }}>Oswald light</option>
                                <option value="Comfortaa, cursive" style={{ fontFamily: "Comfortaa, cursive" }}>Comfortaa</option>
                            </select>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                <ColorModal />
                                <label htmlFor="other_description_1_color_code" className="form-label"><strong style={{ color: 'black' }}>  Apply Custom Color</strong></label>

                            </div>
                            <input
                                type="text"
                                className="form-control"
                                name='other_description_1_color_code'
                                value={fdata.other_description_1_color_code}
                                onChange={changeHandler}
                                id="other_description_1_color_code"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="button_name1" className="form-label d-flex" style={{ justifyContent: "space-between" }}>
                                <strong style={{ color: 'black', fontSize: "12px" }}> Call to action Button text </strong>
                                <span className="form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        style={{ padding: '6px', width: '35px' }}
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        name='show_gallery_button_1'
                                        onClick={checkHandler}
                                        checked={fdata.show_gallery_button_1}
                                    />
                                    <strong style={{ color: 'black', fontSize: "12px" }} for="flexSwitchCheckChecked">Show/Hide</strong>
                                </span>
                            </label>
                            {fdata?.show_gallery_button_1 &&
                                <input
                                    type="text"
                                    className="form-control"
                                    value={fdata?.button_name1}
                                    name='button_name1'
                                    onChange={changeHandler}
                                    id="button_name1"
                                    placeholder="enter button description"
                                />
                            }
                        </div>


                        <div className='col-md-4 mb-3'>
                            {fdata?.show_gallery_button_1 &&
                                <>
                                    <label htmlFor="other_url_1" className="form-label">
                                        <strong style={{ color: 'black', fontSize: "12px" }}>Add URL for call to action button</strong>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={fdata?.other_url_1}
                                        name='other_url_1'
                                        onChange={changeHandler}
                                        id="other_url_1"
                                        placeholder="enter other url"
                                    />
                                </>
                            }
                        </div>

                        <div className='col-md-2 mb-3'>
                            <label htmlFor="other_url_2" className="form-label">
                                <strong style={{ color: 'black', fontSize: "12px" }}> Add Images/Videos </strong>
                            </label>
                            <button type='button' className='btn btn-info mt-1 w-100' onClick={() => addImageHandler("section_1")}> <strong style={{ color: '#fff' }}> Add</strong></button>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="form-label">
                                <strong style={{ color: 'black', fontSize: "12px" }}> Show Images/Videos </strong>
                            </label>
                            <button type='button' className='btn mt-1 w-100' style={{ background: "#02793f" }} onClick={() => showImageFileHandler(fdata?.section_1_Data)}> <strong style={{ color: '#fff' }}> Files {fdata?.section_1_Files}</strong></button>
                        </div>




                        {/* <div className='col-md-3 mb-3 pt-3'>
                            <button type='button' className='btn mt-4 w-100' style={{ background: "#02793f" }} onClick={() => showImageFileHandler(fdata?.section_1_Data)}> <strong style={{ color: '#fff' }}> Show Files {fdata?.section_1_Files}</strong></button> */}
                        {/* <button type='button' className='btn btn-info mt-4 w-100'> <strong style={{ color: '#fff' }} onClick={() => addImageHandler("section_1")} > Add Images or Videos</strong></button> */}
                        {/* </div> */}


                        <div className='col-md-12 mb-3'>
                            <hr />
                        </div>
                    </div>


                    <div className='col-md-12'>
                        <div className='row'>
                            <lable className="form-label d-flex" style={{ justifyContent: 'end' }}>
                                <strong style={{ color: 'black', marginRight: "10px" }}> Show Section - 2 </strong>
                                <div className='form-check'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name='showOtherSection2'
                                        checked={fdata.showOtherSection2}
                                        onChange={checkHandler}
                                        id="showOtherSection2"
                                    />
                                </div>
                            </lable>
                            <div className='col-md-12 mb-2 mt-2'>
                                <hr />
                            </div>
                        </div>
                    </div>



                    {/*  Section -----------2  Title  ********** */}

                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="other_title_2" className="form-label">
                                <strong style={{ color: 'black' }}> Choose a title </strong>
                                {/* <Tooltips info={'Changing the title will not change the titles in the Warrior app, just your website'} /> */}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata?.other_title_2}
                                name='other_title_2'
                                onChange={changeHandler}
                                id="other_title_2"
                                placeholder="enter other titile"
                            />
                        </div>

                        <div className='col-md-4 mb-3'>
                            <label htmlFor="other_title_2_font_family" className="form-label">
                                <strong style={{ color: 'black' }}> Apply Custom Font</strong>
                            </label>
                            <select className="form-control" name="other_title_2_font_family" onChange={changeHandler} value={fdata.other_title_2_font_family} id="other_title_2_font_family">
                                <option value="" selected disabled style={{ fontFamily: 'Arial, sans-serif' }}>Select</option>
                                <option value="Arial, sans-serif" style={{ fontFamily: 'Arial, sans-serif' }}>Arial</option>
                                <option value="Verdana, sans-serif" style={{ fontFamily: 'Verdana, sans-serif' }}>Verdana</option>
                                <option value="Georgia, serif" style={{ fontFamily: 'Georgia, serif' }}>Georgia</option>
                                <option value="Oswald, sans-serif" style={{ fontFamily: 'Oswald light' }}>Oswald light</option>
                                <option value="Comfortaa, cursive" style={{ fontFamily: "Comfortaa, cursive" }}>Comfortaa</option>
                            </select>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                <ColorModal />
                                <label htmlFor="other_title_2_font_color" className="form-label"><strong style={{ color: 'black' }}>  Apply Custom Color</strong></label>

                            </div>
                            <input
                                type="text"
                                className="form-control"
                                name='other_title_2_font_color'
                                value={fdata.other_title_2_font_color}
                                onChange={changeHandler}
                                id="other_title_2_font_color"
                                placeholder="211B16"
                            />
                        </div>

                        {/*  Section -----------2  Description  ********** */}

                        <div className='col-md-4 mb-3'>
                            <label htmlFor="other_description_2" className="form-label">
                                <strong style={{ color: 'black' }}> Add a description below </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata?.other_description_2}
                                name='other_description_2'
                                onChange={changeHandler}
                                id="other_description_2"
                                placeholder="enter other description"
                            />
                        </div>

                        <div className='col-md-4 mb-3'>
                            <label htmlFor="other_description_2_font_family" className="form-label">
                                <strong style={{ color: 'black' }}> Apply Custom Font</strong>
                            </label>
                            <select className="form-control" name="other_description_2_font_family" onChange={changeHandler} value={fdata.other_description_2_font_family} id="other_description_2_font_family">
                                <option value="" selected disabled style={{ fontFamily: 'Arial, sans-serif' }}>Select</option>
                                <option value="Arial, sans-serif" style={{ fontFamily: 'Arial, sans-serif' }}>Arial</option>
                                <option value="Verdana, sans-serif" style={{ fontFamily: 'Verdana, sans-serif' }}>Verdana</option>
                                <option value="Georgia, serif" style={{ fontFamily: 'Georgia, serif' }}>Georgia</option>
                                <option value="Oswald, sans-serif" style={{ fontFamily: 'Oswald light' }}>Oswald light</option>
                                <option value="Comfortaa, cursive" style={{ fontFamily: "Comfortaa, cursive" }}>Comfortaa</option>
                            </select>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                <ColorModal />
                                <label htmlFor="other_description_2_font_color" className="form-label"><strong style={{ color: 'black' }}>  Apply Custom Color</strong></label>

                            </div>
                            <input
                                type="text"
                                className="form-control"
                                name='other_description_2_font_color'
                                value={fdata.other_description_2_font_color}
                                onChange={changeHandler}
                                id="other_description_2_font_color"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="button_name2" className="form-label d-flex" style={{ justifyContent: "space-between" }}>
                                <strong style={{ color: 'black', fontSize: "12px" }}> Call to action Button text </strong>
                                <span className="form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        style={{ padding: '6px', width: '35px' }}
                                        role="switch"
                                        id="flexSwitchCheckChecked2"
                                        name='show_gallery_button_2'
                                        onClick={checkHandler}
                                        checked={fdata.show_gallery_button_2}
                                    />
                                    <strong style={{ color: 'black', fontSize: "12px" }} for="flexSwitchCheckChecked2">Show/Hide</strong>
                                </span>
                            </label>
                            {fdata?.show_gallery_button_2 &&
                                <input
                                    type="text"
                                    className="form-control"
                                    value={fdata?.button_name2}
                                    name='button_name2'
                                    onChange={changeHandler}
                                    id="button_name2"
                                    placeholder="enter button description"
                                />
                            }
                        </div>
                        <div className='col-md-4 mb-3'>

                            {fdata?.show_gallery_button_2 &&
                                <>
                                    <label htmlFor="other_url_2" className="form-label">
                                        <strong style={{ color: 'black', fontSize: "12px" }}> Add URL for call to action button </strong>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={fdata?.other_url_2}
                                        name='other_url_2'
                                        onChange={changeHandler}
                                        id="other_url_2"
                                        placeholder="enter other url"
                                    />
                                </>
                            }
                        </div>

                        <div className='col-md-2 mb-3'>
                            <label htmlFor="other_url_2" className="form-label">
                                <strong style={{ color: 'black', fontSize: "12px" }}> Add Images/Videos </strong>
                            </label>
                            <button type='button' className='btn btn-info mt-1 w-100' onClick={() => addImageHandler("section_2")}> <strong style={{ color: '#fff' }}> Add</strong></button>
                        </div>

                        <div className='col-md-2 mb-3'>
                            <label className="form-label">
                                <strong style={{ color: 'black', fontSize: "12px" }}> Show Images/Videos </strong>
                            </label>
                            <button type='button' className='btn mt-1 w-100' style={{ background: "#02793f" }} onClick={() => showImageFileHandler(fdata?.section_2_Data)}> <strong style={{ color: '#fff' }}> Files {fdata?.section_2_Files}</strong></button>
                        </div>

                        <div className='col-md-12'>
                            <hr />
                        </div>
                    </div>


                    <div className='row mb-5 mt-3'>
                        <button type='submit' className='btn btn-info w-100'> <strong style={{ color: '#fff' }}> Update</strong></button>
                    </div>
                </form>
            </div>


            <Modal show={addMore?.show} onHide={() => { setAddMore((pre) => ({ ...pre, show: false })); setAddMore((pre) => ({ ...pre, type: '' })) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Video Or Images Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <label htmlFor="data_type" className="form-label">
                                <strong style={{ color: 'black' }}>   Select Type</strong>
                            </label>
                            <select className="form-control" name="data_type" value={gallery?.data_type} onChange={changeNewHandler} id="data_type">
                                <option value="" selected disabled >Select</option>
                                <option value="image">Image</option>
                                <option value="video">Video</option>
                            </select>
                            <small style={{ color: "red" }}>{galleryError?.data_type}</small>
                        </Col>
                        <Col md={8}>
                            <label htmlFor="data_file" className="form-label">
                                <strong style={{ color: 'black' }}> Image/Video </strong>
                                <Tooltips info={'image max-size: 200mb, video max-size: 400mb'} />
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                name='data_file'
                                id='data_file'
                                value={gallery?.image}
                                onChange={changeNewHandler}
                                placeholder="Select"
                            />
                            <small style={{ color: "red" }}>{galleryError?.data_file}</small>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='button' variant="Danger" onClick={() => { setAddMore((pre) => ({ ...pre, show: false })); setAddMore((pre) => ({ ...pre, type: '' })) }}>
                        Close
                    </Button>
                    <Button type='button' onClick={() => addNewFileHandler(addMore?.type)} variant="primary" >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>


            <UpdateOthersFiles data={showFiles?.data} setLoder={setLoder} showFiles={showFiles} setShowFiles={setShowFiles} webinformaition={webinformaition}  />


        </>
    )
}

import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Form, Alert, Spinner } from "react-bootstrap";
import Sidebar from "../comman_page/Sidebar";
import { HeaderDashboard } from "../comman_page/HeaderDashboard";
import { Footer } from "../../../../public/containers/footer/Footer";
import { InputField } from "../../../../sharedComponents/InputField";
import { SharedSelect } from "../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../sharedComponents/Button";
import { MyDatePicker } from "../../../../sharedComponents/MyDatePicker";
import { MyContext } from "../../../../App";
import { TextArea } from "../../../../sharedComponents/TextArea";
import { clover_access, getProfile, getUserProfileInfo, studio_clover_generate_token, update_business_profile } from "../../../../Studio-Services/Studio_Services";
import { errorAlert, successAlert } from "../../../../Static_Services/Alertmsg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logo from '../../../../Images/logo_white_web.png';
import Swal from "sweetalert2";
import PlaceAutoCompleted from "../../../../client-staff/Components/CommanPages/PlaceAutoCompleted";


export const BusinessProfile = () => {
  const marchant_id = localStorage.getItem("marchant_id");
  const cl_token_expire = localStorage.getItem("cl_token_expire");

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);


  const {addTodo} = useContext(MyContext);

  // const employee_id = queryParams.get('employee_id');
  // const merchant_id1 = queryParams.get('merchant_id');

  // *************************************************  //

  const [currentUser, setCurrentUser] = useState();
  const [cloverMarchentId, setCloverMarchentId] = useState();
  const [cloverEmployeeId, setCloverEmployeeId] = useState();
  const [cloverTokenExpire, setCloverTokenExpire] = useState();
  const [business_token, setBusiness_token] = useState();
  const [list_token, setList_token] = useState();
  const [month_token, setMonth_token] = useState();
  // const [clovertoken, setClovertoken] = useState({ merchant_id: '', employee_id: '', client_id: '', code: '' });
  const navigate = useNavigate();
  const [cloverQuery, setCloverQuery] = useState(false)
  const [cloverRequestStatus, setCloverRequestStatus] = useState(false);
  const [businessProfiledData, setBusinessProfiledData] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imgURL, setImgURL] = useState(null);
  const [lattitude, setLattitude] = useState();
  const [longitude, setLongitude] = useState();
  const [myBusinessProfile, setMyBusinessProfile] = useState({ business_name: '', address: '', owner_details: '', business_phone: '', primary_email: '', website: '', become_member: '' });
  const [myBusinessProfileError, setMyBusinessProfileError] = useState({ business_name: '', address: '', owner_details: '', business_phone: '', primary_email: '', website: '', become_member: '' });
  const [loder, setLoder] = useState(false);

  const [cordinates, setCordinates] = useState();
  

  const generateCloverToken = async (clovertoken) => {

    const resp = await studio_clover_generate_token(clovertoken);
    if (resp) {
      successAlert('Clover setup completed');
      navigate('/Home');
      return;
    }
  }

  useEffect(() => {
    const size = queryParams.size;
    if (size > 0) {
      const clovertoken = {
        merchant_id: queryParams.get('merchant_id'),
        employee_id: queryParams.get('employee_id'),
        client_id: queryParams.get('client_id'),
        code: queryParams.get('code')
      };
      generateCloverToken(clovertoken);
      return;
    }
  }, [queryParams]);


  useEffect(() => {
    if (JSON.parse(localStorage.getItem('st_userData'))) {
      setCurrentUser(JSON.parse(localStorage.getItem('st_userData')));
    }
    businessProfile();
    getUserProfile();
  }, [])
  
  const getUserProfile = async () => {
    const resp = await getUserProfileInfo();
    if (resp) {
      const userData = resp.data.data;
      if (userData.business_img) {
        setImgURL(userData.business_img);
      }
      setCloverMarchentId(userData.marchant_id);
      setCloverEmployeeId(userData.cl_employee_id);
      setCloverTokenExpire(userData.cl_token_expire);
      setBusiness_token(userData.business_token);
      setList_token(userData.list_token);
      setMonth_token(userData.month_token);
    }
  }

  const cloverCreate = async () => {
    let clover_app_id = '';
    let clover_app_secret = '';
    if (process.env.REACT_APP_CLOVER_APP_ID) {
      clover_app_id = process.env.REACT_APP_CLOVER_APP_ID.trim();
    }
    if (process.env.REACT_APP_CLOVER_APP_SECERATE) {
      clover_app_secret = process.env.REACT_APP_CLOVER_APP_SECERATE.trim();
    }
    if (clover_app_id.length === 0) {
      errorAlert('Please enter clover App Id');
      return false;
    }
    if (clover_app_secret.length === 0) {
      errorAlert('Please enter clover App Secret');
      return false;
    }
    setCloverRequestStatus(true);
    let temp = {};
    temp.clover_app_id = clover_app_id;
    temp.clover_app_secret = clover_app_secret;
    const resp = await clover_access(temp);
    if (resp) {
      setCloverRequestStatus(false);
      window.open(resp.data.clover_url, '_blank');
    }
  }




  // **************************************************  //


  const options = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
  ];

  const ContractorOptions = [
    { value: "Employee", label: "Employee" },
    { value: "Contractor", label: "Contractor" },
    { value: "Volunteer", label: "Volunteer" },
  ];

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    // Do something with the selected value
  };


  const businessProfile = async () => {
    const resp = await getProfile();
    if (resp) {
      let response = resp.data.data;
      setBusinessProfiledData(response);
      setMyBusinessProfile({
        business_name: response.business_name,
        address: response.address,
        owner_details: response.owner_details,
        business_phone: response.business_phone,
        primary_email: response.primary_email,
        website: response.website,
        become_member: response.become_member
      });
      setImgURL(response.logo);
      setLattitude(response.lat);
      setLongitude(response.longitude);
    }
  }

  const copyMonthToken = (type = 1) => {
    const el = document.createElement('textarea');
    el.value = month_token + '&type=' + type;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    successAlert('Link Copied');
  }


  const setBusinessprofile = async (e) => {
    e.preventDefault();
    let isValid = 1;
    if (!myBusinessProfile.business_name) { setMyBusinessProfileError((pre) => ({ ...pre, business_name: 'Required' })); isValid = 2 }
    if (!myBusinessProfile.address) { setMyBusinessProfileError((pre) => ({ ...pre, address: 'Required' })); isValid = 2 }
    if (!myBusinessProfile.owner_details) { setMyBusinessProfileError((pre) => ({ ...pre, owner_details: 'Required' })); isValid = 2 }
    // if (!myBusinessProfile.business_phone) { setMyBusinessProfileError((pre) => ({ ...pre, business_phone: 'Required' })); isValid = 2 }
    if (!myBusinessProfile.primary_email) { setMyBusinessProfileError((pre) => ({ ...pre, primary_email: 'Required' })); isValid = 2 }
    if (!myBusinessProfile.website) { setMyBusinessProfileError((pre) => ({ ...pre, website: 'Required' })); isValid = 2 }
    if (!myBusinessProfile.become_member) { setMyBusinessProfileError((pre) => ({ ...pre, become_member: 'Required' })); isValid = 2 }

    if (isValid === 1) {

      const setAddress = cordinates.getAddress || businessProfiledData.address;
      const setCountry = cordinates.getCountry || businessProfiledData.country;
      const setState = cordinates.getState || businessProfiledData.state;
      const setCity = cordinates.getCity || businessProfiledData.city;
      const setZIP_Code = cordinates.getZIP_Code || businessProfiledData.zipcode;
      const setLat = cordinates.getLat || businessProfiledData.lat;
      const setLng = cordinates.getLng || businessProfiledData.longitude;
      const setLocation_name = cordinates.getLocation_name || businessProfiledData.location_name;
      let formData = new FormData();
      setLoder(true);
      formData.append('business_name', myBusinessProfile.business_name);
      formData.append('email', myBusinessProfile.primary_email);
      formData.append('mobile', myBusinessProfile.business_phone);
      formData.append('website', myBusinessProfile.website);
      if (selectedFile) {
        formData.append('business_logo', selectedFile);
      } else {
        formData.append('business_logo', businessProfiledData.logo);
      }
      formData.append('business_address', setAddress);
      formData.append('owner_details', myBusinessProfile.owner_details);
      formData.append('lat', setLat);
      formData.append('lang', setLng);
      formData.append('country', setCountry);
      formData.append('state', setState);
      formData.append('city', setCity);
      formData.append('location_name', setLocation_name);
      formData.append('zipcode', setZIP_Code);
      formData.append('become_member', myBusinessProfile.become_member);

      const response = await update_business_profile(formData);
      if (response) {
        const resp = response.data.data;
        if (localStorage.getItem('st_userData')) {
          let businessProfile = JSON.parse(localStorage.getItem('st_userData'));
          if ('thumb_img' in resp) {
            if (resp.thumb_img != '') {
              businessProfile.logothumb = resp.thumb_img;
              businessProfile.business_thumb = resp.thumb_img;
            }
          }
          businessProfile.business_img = resp.url + resp.business_image;
          businessProfile.business_name = resp.business_name;
          localStorage.setItem('st_userData', JSON.stringify(businessProfile));
          addTodo(businessProfile);
        }
        setLoder(false);
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
            <h4 class="title text-uppercase text-center mt-4 textWhite">
            SUCCESS
          </h4>
          <p class="text-center my-4 textcolor">
          <small class="textWhite">
          ${response.data.message} </small></p>
            `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/Home");
          }
        });
      } else {
        setLoder(false);
      }

    } else {
      return false;
    }

  }



  const uploadImage = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        setImgURL(event.target.result);
      };
    }
  };



  const changeHandler = (e) => {
    const { name, value } = e.target;
    let preData = myBusinessProfile;
    preData[name] = value;
    setMyBusinessProfile(preData);
    setMyBusinessProfileError((pre) => ({ ...pre, [name]: '' }))
  }

  return (
    <>
      {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div>}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  {businessProfiledData &&
                    <Container className="pt-5">
                      <div className="AddClientForm AddClientContent">
                        <h4>UPDATE YOUR BUSINESS PROFILE</h4>
                        <h6>BUSINESS LOGO (JPEG, PNG)</h6>
                        <Form autoComplete="off" onSubmit={setBusinessprofile}>
                          <Row>
                            <Col md={2}>
                              <div className="form-group">
                                <div className="custom-uploadfile mb-3">
                                  {!imgURL &&
                                    <button className="fileuploadlabel" type="button">
                                      <img src="assets/images/icons/ic_uploadimg.png" className="img-fluid" />
                                      <span className="d-block font-14">Upload profile images</span>
                                    </button>
                                  }
                                  {imgURL &&
                                    <button className="fileuploadlabel" type="button">
                                      <img src={imgURL} className="img-fluid" style={{ height: "100px" }} />
                                      <span className="d-block font-14">Change image </span>
                                    </button>
                                  }
                                  <input type="file" className="fileuploadinput" accept="image/x-png,image/gif,image/jpeg"
                                    formControlName="profileimg" onChange={(e) => uploadImage(e)} />
                                </div>
                              </div>
                            </Col>

                          </Row>
                          <Row className="mt-3">
                            <Col>
                              <InputField
                                type={"text"}
                                name={"business_name"}
                                lable={"Business Name"}
                                value={myBusinessProfile.business_name}
                                onChange={(e) => changeHandler(e)}
                                error={myBusinessProfileError.business_name}
                                placeholder={"Enter Name"}
                              />
                            </Col>
                            <Col>
                              <PlaceAutoCompleted
                                label={'Head Quarters Address'}
                                value={myBusinessProfile.address}
                                setCordinates={setCordinates}
                              />
                            </Col>
                            <Col>
                              <InputField
                                type={"text"}
                                lable={"Owners Name"}
                                name={"owner_details"}
                                value={myBusinessProfile.owner_details}
                                onChange={(e) => changeHandler(e)}
                                error={myBusinessProfileError.owner_details}
                                placeholder={"Enter Number"}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <h5 className="py-3">CONTACT DETAILS</h5>
                            <Col>
                              <InputField
                                type={"number"}
                                lable={"Telephone/Landline"}
                                name={"business_phone"}
                                value={myBusinessProfile.business_phone}
                                placeholder={''}
                                onChange={(e) => changeHandler(e)}
                                error={myBusinessProfileError.business_phone}
                              />
                            </Col>
                            <Col>
                              <InputField
                                type={"email"}
                                lable={"Primary Email ID"}
                                name={"primary_email"}
                                value={myBusinessProfile.primary_email}
                                placeholder={"example@gmail.com"}
                                onChange={(e) => changeHandler(e)}
                                error={myBusinessProfileError.primary_email}
                              />
                            </Col>
                            <Col>
                              <InputField
                                type={"text"}
                                lable={"Website"}
                                name={"website"}
                                value={myBusinessProfile.website}
                                placeholder={"www.bestrong.com"}
                                onChange={(e) => changeHandler(e)}
                                error={myBusinessProfileError.website}
                              />
                            </Col>
                          </Row>
                          <Row className="">
                            <h5 className="py-3">
                              BECOME A MEMBER (WAVIER T&C'S)
                            </h5>
                            <Col>
                              <TextArea
                                name="become_member"
                                value={myBusinessProfile.become_member}
                                rows="3"
                                onChange={(e) => changeHandler(e)}
                                error={myBusinessProfileError.become_member}
                              />
                            </Col>
                          </Row>
                          <Row className="py-4">
                            <Col>
                              <SharedButton
                                variant={"primary"}
                                type="submit"
                                className={"UpdatedBtn w-100"}
                                title={"Save & Continue"}
                              />
                            </Col>
                            <Col>
                              <SharedButton
                                variant={"outline"}
                                type={"button"}
                                className={"CancelBtn w-100"}
                                title={"Cancel"}
                              />
                            </Col>
                            <Col></Col>
                            <Col></Col>
                          </Row>
                        </Form>
                        <Row className="pb-3">
                          <h5 className="">IFRAME DETAILS</h5>
                          <Col md={10}>
                            <InputField
                              readOnly
                              value={month_token}
                              type={"text"} lable={"Classes List"} />
                          </Col>
                          <Col md={2}>
                            <SharedButton
                              title={"Copy"}
                              onClick={() => copyMonthToken(1)}
                              variant={"primary"}
                              className={"CopyBtn"}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <h5 className="title mt-3 text-uppercase">Clover Details</h5>
                          <div className="row mt-4">
                            <div className="form-group col-md-4" style={{ display: "none" }}>
                              <label>Clover App Id</label>
                              <input type="text" className="form-control"
                                //  [(ngModel)]="clover_app_id"
                                // [ngModelOptions]="{standalone: true}"
                                required />
                            </div>
                            <div className="form-group col-md-4" style={{ display: "none" }}>
                              <label>Clover App Secret Key</label>
                              <input type="text" className="form-control"
                                required />
                            </div>
                            <div className="form-group col-md-4">
                              <label>Clover Marchent ID</label>
                              <input type="text" className="form-control" value={cloverMarchentId}
                                readonly />
                            </div>
                            <div className="form-group col-md-4" style={{ display: "none" }}>
                              <label>Clover Employee Id</label>
                              <input type="text" className="form-control" value={cloverEmployeeId}
                                readonly />
                            </div>
                            <div className="form-group col-md-4">
                              <label>Clover Token Expire</label>
                              <input type="text" className="form-control" value={cloverTokenExpire}
                                readonly />
                            </div>
                            {(!cloverQuery) &&
                              <div className="form-group col-md-4">
                                <button className="btn btn-brandbluegrad"
                                  onClick={() => cloverCreate()}
                                  style={{ marginTop: "10%" }}>
                                  {cloverRequestStatus && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>} Generate Token</button>
                              </div>
                            }
                          </div>
                        </Row>
                      </div>
                    </Container>
                  }
                </section>
                <Footer />
              </div>
            </Col>
          </Row >
        </div >
      </div >
    </>
  );
};

import React, { useEffect, useState } from "react";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import Sidebar from "../../comman_page/Sidebar";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { Container, Row, Col } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { ServiceCard } from "../../../../../sharedComponents/ServiceCard";
import { book_appointment, get_customer_list, service_scheduling_time_slot, } from "../../../../../Studio-Services/Studio_Services";
import { SharedMultiSelectorChekBox } from "../../../../../sharedComponents/SharedMultiSelectorChekBox";
import { SheduleappointmentStep2 } from "./SheduleappointmentStep2";
import { Appointmentchekbox } from "./Appointmentchekbox";
import { SheduleappointmentCart } from "./SheduleappointmentCart";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { StepThree } from "./StepThree";
import { Alert, Spinner } from 'react-bootstrap';
import moment from "moment";
import { StepFour } from "./StepFour";

export const Sheduleappointment = () => {

  const [loder, setLoder] = useState(false);
  const [data, setData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [checkid, setCheckid] = useState([]);
  const [service, setService] = useState([]);
  const [step, setStep] = useState(0);
  const [stapeTwoData, setStapeTwoData] = useState([]);
  const [selectedSlote, setSelectedSlote] = useState();
 
  const [instid, setInstid] = useState();
  const [serv, setServ] = useState();
  const [stepThreeData, setStepThreeData] = useState();
  const [userSelectedDate, setUserSelectedDate] = useState();
  const [shiftData,setShiftData] = useState();

  const getService = async () => {
    const data = { pageid: 1 };
    const resp = await book_appointment(data);
    if (resp) {
      setData(resp.data);
    }
  };

  const getCostomerList = async () => {
    const list = await get_customer_list();
    if (list) {
      setClientList(list.data);
    }
  };

  useEffect(() => {
    getService();
    getCostomerList();
  }, []);

  const shiftHandler = (data)=>{
    setShiftData(data);
  }

  const array = [];
  if (data) {
    data.forEach((element) => {
      array.push({
        memberShipImg: localStorage.getItem("logo")
          ? localStorage.getItem("logo")
          : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
        cardTitle: element.service_name,
        charge: "Service Charge :",
        duration: "Service Duration :",
        price: "$ " + element.amount,
        min: element.duration + " Min",
        fullData: element,
      });
    });
  }

  const option = [];

  if (clientList) {
    clientList.forEach((e) => {
      option.push({
        id: e.id,
        label: e.name
      });
    });
  }

  const step2 = (service) => {
    setStapeTwoData([]);
    if (service) {
      if (service.instructor.length > 0) {
        if (checkid.length > 0) {
          let clientFullDetail = [];
          clientList.forEach((e) => {
            if (e.id === checkid[0].id) {
              clientFullDetail.push(e);
            }
          });
          setStep(1);
          setServ(service.id);
          // setInstid(service.instructor[0].id);
          const instructorData = service.instructor;
          const serviceData = service;
          const clientId = checkid[0].id;
          const stepTwo = {
              iData: instructorData,
              sData: serviceData,
              cId: clientId,
              ClientFullDetails: clientFullDetail,
              finalDetail:{
                clientID:checkid[0].id,
                serviceID:service.id,
              },
            } ;
          setStapeTwoData(stepTwo);
        } else {
          Swal.fire({
            html: `
              <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
              <h4 class="title text-uppercase text-center mt-4 textWhite">
              Sorry
              </h4>
              <p class="text-center my-4 textWhite">
                <small class="textWhite">
                  Please select the customer
                </small>
              </p>
            `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
          })
        }
      } else {
        Swal.fire({
          html: `
            <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
            <h4 class="title text-uppercase text-center mt-4 textWhite">
            WARNING
            </h4>
            <p class="text-center my-4 textWhite">
              <small class="textWhite">
              No instructor assign
              </small>
            </p>
          `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        })
      }
    } else {
      alert("please select service");
    }
  };

  const handleGoBack = () => {
    // Go back in the browser's history
    window.history.back();
  };
  const loders = (data) => {
    setLoder(data);
  }

  const bookHandlerStepTwo = async (e) => {
    setSelectedSlote();
    loders(true);
    const selectedDate = moment(new Date(e), 'ddd MMM DD YYYY HH:mm:ss');
    const newTime = moment(selectedDate).unix();
    setUserSelectedDate(newTime);
    const fData = {
      "service_id": serv,
      "instructor_id": instid,
      "service_date": newTime
    }
    const resp = await service_scheduling_time_slot(fData);
    if (resp) {
      loders(false);
      setStepThreeData(resp.data);
    }
    if (resp == undefined) {
      setStepThreeData();
      loders(false);
    }
  }

  const bookHandler = async (e) => {
    // setStapeTwoData
    const instrd = {...stapeTwoData};
    instrd.iData=e;
    setStapeTwoData(instrd);

    console.log('step--2-----',e);
    setInstid(e.id);
    loders(true);
    setStep(2);
    let selectedDate = "";
    if (e) {
      selectedDate = moment(new Date(e), 'ddd MMM DD YYYY HH:mm:ss');
    }
    const newTime = moment(selectedDate).unix();
    let dates = "";
    if (newTime) {
      dates = newTime
    } else {
      dates = moment().startOf('day').unix();
    }
    const fData = {
      "service_id": serv,
      "instructor_id": e.id,
      "service_date": dates
    }
    const resp = await service_scheduling_time_slot(fData);
    if (resp) {
      loders(false);
      setStepThreeData(resp.data);
    }
    if (resp == undefined) {
      setStepThreeData();
      loders(false);
    }
  }

  const sloteHandler = (data) => {
    setSelectedSlote(data);
  }

  return (
    <>
      {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                {step === 0 ? (
                  <section className="innerContent">
                    <Container>
                      <div className="stateBoxPanel m-3 p-3">
                        <div className="appointmentclassContent">
                          <Row>
                            <h5 style={{ cursor: "pointer" }} > <IoIosArrowBack className="m-3" /> BOOK APPOINTMENT </h5>
                            <Col>
                              <Appointmentchekbox labelText="Choose Client" name="skills" setSkillsdata={setCheckid} options={option} />
                            </Col>
                            <Col>
                              <SharedButton variant={"primary"} title={"Add New Client"} className={"addClientBtn mt-4"} href={"/add-client"} />
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row className="mt-3">
                            <p>Choose Service</p>
                            {/*  Page One */}
                            <Col>
                              <SheduleappointmentCart onClick={(value) => step2(value)} cardDetail={array} />   
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Container>
                  </section>
                ) :


                  step === 1 ? (
                    // Stap Two Data

                    <section className="innerContent">
                      <Container>
                        <div className="stateBoxPanel m-5">
                          <div className="appointmentclassContent">
                            <Row>
                              <h5 style={{ cursor: "pointer" }}
                                onClick={() => setStep(0)}>
                                <IoIosArrowBack className="m-3" />
                                BOOK APPOINTMENT
                              </h5>
                              <Col>
                                <div></div>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <SheduleappointmentStep2
                                  cardDetail={stapeTwoData}
                                  step={step}
                                  onClick={bookHandler}
                                  setStep={setStep}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Container>
                    </section>

                    //  Section 3
                  )
                    : step === 2 ? (
                      <section className="innerContent">
                        <Container>
                          <div className="stateBoxPanel m-5">
                            <div className="appointmentclassContent">
                              <Row>
                                <h5 style={{ cursor: "pointer" }} onClick={() => setStep(1)}>
                                  <IoIosArrowBack className="m-3" />
                                  BOOK APPOINTMENT
                                </h5>
                                <Col>
                                  <div></div>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col>
                                  <StepThree
                                    apiData={stepThreeData}
                                    cardDetail={stapeTwoData}
                                    loders={loders}
                                    step={step}
                                    shiftHandler={shiftHandler}
                                    userSelectedDate={userSelectedDate}
                                    selectedSlote={selectedSlote}
                                    sloteHandler={sloteHandler}
                                    onClick={bookHandlerStepTwo}
                                    setStep={setStep}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Container>
                      </section>
                    ) : step === 3 ? (
                      <section className="innerContent">
                        <Container>
                          <div className="stateBoxPanel m-5">
                            <div className="appointmentclassContent">
                              <Row>
                                <h5 style={{ cursor: "pointer" }} onClick={() => setStep(2)}>
                                  <IoIosArrowBack className="m-3" />
                                  BOOK APPOINTMENT
                                </h5>
                                <Col>
                                  <div></div>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col>
                                  <StepFour
                                    cardDetail={stapeTwoData}
                                    slotTime={selectedSlote}
                                  
                                    slotDate={userSelectedDate}
                                    shiftData={shiftData}
                                    loders={loders}
                                    step={step}
                                    setStep={setStep}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Container>
                      </section>
                    ) : ""}
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

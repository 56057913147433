import React, { useEffect, useRef, useState } from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Modal, Spinner } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'
import { API_cardGet, API_clover_buy_now_single, API_pay_at_desk, API_update_cart, ExpiryCheck } from '../../../Static_Services/Staff_Services'

export const ProductPurchase = () => {
  const [loder, setLoder] = useState(false);
  const navigate = useNavigate();
  const [businessDetail, setBusinessDetail] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const location = useLocation();
  const [productDetail, setProductDetail] = useState();
  const [productCount, setProductCount] = useState(1);
  const [customerData, setCustomerData] = useState();
  const [savecard, setSavecard] = useState(0);
  const [cardType, setCardType] = useState();
  const [paymentval, setPaymentval] = useState();
  const [productTax, setProductTax] = useState(0);
  const [modalshow, setModalshow] = useState(false);



  useEffect(() => {
    if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
      setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')))
    }
    if (JSON.parse(localStorage.getItem('w_loginDetail'))) {
      setUserInfo(JSON.parse(localStorage.getItem('w_loginDetail')));
    }
    const navData = location.state;
    if (navData.Detail) {
      localStorage.setItem('w_ProductDetail', JSON.stringify(navData.Detail));
    }
    if (JSON.parse(localStorage.getItem('w_ProductDetail'))) {
      setProductDetail(JSON.parse(localStorage.getItem('w_ProductDetail')))
    }
  }, [])


  const updateCount = (value, data) => {
    if (value == '+') {
      if (productCount < productDetail.product_quantity) {
        const updatedCount = productCount + 1;
        setProductCount(updatedCount);
        const datas = { quantity: updatedCount, product_id: data.product_id };
        updateCartDetail(datas);
      } else {
        errorAlert('Product Not available');
        return false;
      }
    } else {
      if (productCount > 1) {
        const updatedCount = productCount - 1;
        setProductCount(updatedCount);
        const datas = { quantity: updatedCount, product_id: data.product_id };
        updateCartDetail(datas);
      } else {
        errorAlert('Minimum Quantity is 1')
        return false;
      }
    }
  }

  const updateCartDetail = async (data) => {
    setLoder(true);
    const detail = { "cart_id": "", "quantity": data.quantity, "product_id": data.product_id };
    const resp = await API_update_cart(detail);
    if (resp) {
      const response = resp.data;
      successAlert(response.message);
      const prd = productDetail;
      prd.product_price = response.data.total_amount;
      prd.product_tax_price = response.data.tax;
      prd.product_total_price = response.data.grand_total;
      prd.tax1_rate = response.data.tax1_rate;
      prd.tax2_rate = response.data.tax2_rate;
      setProductDetail(prd);
      setLoder(false);
    } else {
      setLoder(false);
    }
  }

  const payment = () => {
    //   var expired = ExpiryCheck(paymentCard.value.expiry);
    //   if(expired)  {
    //     this.paymentCard.value.expiry_month = expired[0];
    //     this.paymentCard.value.expiry_year = expired[1];
    //   }  else{
    //     return false;
    //   }
    //   this.submitted = true;

    //   let card = this.paymentCard;
    //   if(card.valid){
    //     this.loading = true;    
    //     var detail = {
    //       "service_type":"3",
    //       "service_id":this.ProductDetail.product_id,
    //       "quantity":this.ProductCount,
    //       "grand_total":this.ProductDetail.product_total_price,
    //       "slot_date":"",
    //       "slot_time_id":"",
    //       "token": this.paymentCard.value.token ,
    //       "savecard": this.savecard,
    //       "card_id" : '',
    //       "business_id":this.businessDetail.business_id,
    //       "number": this.paymentCard.value.card_number,
    //       "expiry_month": this.paymentCard.value.expiry_month,
    //       "expiry_year": this.paymentCard.value.expiry_year,
    //       "cvd": this.paymentCard.value.cvv_no,
    //       "country_code":"",
    //       "customer_name":this.paymentCard.value.card_holder_name,
    //     };
    //    //var url = 'superadmin/webservices/api/buy_now';
    //     // var url = 'superadmin/webservices/api/clover_buy_now';   
    //     var url = 'superadmin/webservices/api/clover_buy_now_single';    
    //     this.commonservice.PostApi(detail,url).subscribe(((response : any) => {
    //       if(response.status == 1){
    //         this.commonservice.successAlert(response.message);
    //         this.loading = false;
    //         this.submitted = false;
    //         this.paymentCard.reset();
    //         this.route.navigate(['/product']);
    //       } else{
    //         this.commonservice.dangerAlert(response.message);
    //         this.loading = false;
    //       }
    //     })) 

    // } else{
    //   this.loading = false;
    //   return false;
    // }
  }

  const savePayment = async (card) => {
    setLoder(true);
    const detail = {
      "service_type": "3",
      "service_id": productDetail.product_id,
      "quantity": productCount,
      "grand_total": productDetail.product_total_price,
      "slot_date": "",
      "slot_time_id": "",
      "token": card.card_token,
      "savecard": 0,
      "card_id": card.card_id,
      "business_id": businessDetail.business_id,
      "country_code": "",
    };
    const resp = await API_clover_buy_now_single(detail);
    if (resp) {
      const response = resp.data;
      successAlert(response.message);
      setLoder(false);
      navigate('/my-purchase');
    } else {
      setLoder(false);
    }
  }

  const getSaveCardDetail = async (userID, businessID) => {
    setLoder(true);
    var detail = { "userid": userID, 'business_id': businessID };
    const resp = await API_cardGet(detail);
    if (resp) {
      const response = resp.data;
      response.data.map((el) => {
        el.checked = false;
      })
      setCustomerData(response.data);
      setLoder(false);
    } else {
      setLoder(false);
    }
  }



  const getCardType = (event) => {
    setCardType(event.target.id);
    if (customerData && customerData.length > 0) {
      customerData?.map((el) => {
        if (el.card_id == cardType) {
          el.checked = event.target.checked;
        }
      })
    }
  }

  const PayAtStudio = async () => {
    setLoder(true);
    const detail = {
      "service_type": "3",
      "service_id": productDetail.product_id,
      "quantity": productCount,
      "slot_date": "",
      "slot_time_id": "",
      "business_id": businessDetail.business_id,
      "amount": productDetail.product_price,
      "tax": productDetail.product_tax_price,
      "instructor_id": ""
    };
    const resp = await API_pay_at_desk(detail);
    if (resp) {
      const response = resp.data;
      successAlert(response.message);
      setLoder(false);
      navigate('/my-purchase');
    } else {
      setLoder(false);
    }
  }

  const redirectUrl = (url, Id) => {
    navigate(url, { state: { Id: Id } });
  }

  useEffect(() => {
    if (businessDetail && businessDetail?.business_id && userInfo && userInfo?.id) {
      const newUserId = userInfo?.id;
      const newBusinessId = businessDetail?.business_id;
      getSaveCardDetail(newUserId, newBusinessId);
    }
  }, [businessDetail, userInfo])

  const [carderror, setCarderror] = useState({ name: "", crnumber: "", dates: "", cvv: "" });
  const [cardData, setCardData] = useState({ name: "", crnumber: "", dates: "", cvv: "" });
  const [expiryDate, setExpiryDate] = useState();
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      if (inputValue.endsWith('/')) {
        setExpiryDate(inputValue.slice(0, -1));
      } else { setExpiryDate(inputValue); }
    } else {
      if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
        setExpiryDate(inputValue + '/');
      } else if (inputValue.length === 8) { return; } else { setExpiryDate(inputValue); }
    }
  };

  const SavecardHandeler = (event) => {
    if (event.target.checked == true) {
      setSavecard(1);
    }
    else { setSavecard(0) }
  }

  const CardInputChange = (e) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/^\s+/g, '');
    setCardData((pre) => ({ ...pre, [name]: cleanedValue }));
    setCarderror((pre) => ({ ...pre, [name]: "" }));
  }


  const paymentHandler = async () => {

    let isValid = 1;
    let expiry_month = '';
    let expiry_year = '';
    let expired = '';

    if (expiryDate) {
      expired = ExpiryCheck(expiryDate);
    }
    if (expired) {
      expiry_month = expired[0];
      expiry_year = expired[1];
    } else {
      setCarderror((pre) => ({ ...pre, dates: "* Valid Till is required" }));
      isValid = 2;
    }
    if (!cardData.name) { setCarderror((pre) => ({ ...pre, name: "* Card Holder Name is required" })); isValid = 3; };
    if (!cardData.crnumber) {
      setCarderror((pre) => ({ ...pre, crnumber: "* Card Number is required" }))
      isValid = 4;
    };
    if (!cardData.cvv) {
      setCarderror((pre) => ({ ...pre, cvv: "* CVV is required" }));
      isValid = 5;
    };
    if (isValid === 1) {
      setLoder(true);
      const detail = {
        "service_type": "3",
        "service_id": productDetail.product_id,
        "quantity": productCount,
        "grand_total": productDetail.product_total_price,
        "slot_date": "",
        "slot_time_id": "",
        "token": '',
        "savecard": savecard,
        "card_id": '',
        "business_id": businessDetail.business_id,
        "number": cardData.crnumber,
        "expiry_month": expiry_month,
        "expiry_year": expiry_year,
        "cvd": cardData.cvv,
        "country_code": "",
        "customer_name": cardData.name,
      };
      const resp = await API_clover_buy_now_single(detail);
      if (resp) {
        const response = resp.data;
        setCardData({ name: "", crnumber: "", dates: "", cvv: "" });
        successAlert(response.message);
        setLoder(false);
        navigate('/my-purchase');
        // window.history.back();
      } else {
        setCardData({ name: "", crnumber: "", dates: "", cvv: "" });
        setLoder(false);
      }
    } else {
      setLoder(false);
      return false;
    }
  }




  return (
    <>

      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />
      <div className="body-grey">

        <div class="studio-details">
          <div class="container">
            <DashboardToSectionDetails />
            <div class="pb-5">
              <div class="row">
                <div class="col-6 text-left">
                  <h4 class="title black-color text-uppercase"> products details </h4>
                </div>
                <div class=" col-6 text-right mb-3">
                  <button type="button" class="btn btn-blue-grad px-2 text-uppercase" onClick={() => window.history.back()} >Back </button>
                </div>
              </div>
              {/* <!-- End of Heading --> */}

              <div class="s-d-appoint-block">
                <div class="row">
                  <div class="col-12 col-lg-6 mt-3 mt-lg-0">
                    <div class="client-buy-input border-radius6 box-shadow1 white-bg p-4">
                      <div class="row">
                        <div class="col-12 mb-4">
                          <div class="white-bg border-radius6 box-shadow1 p-4">
                            <div class="row">
                              <div class="col-3 pr-0">
                                <div class="border-radius6 box-shadow1 p-3">
                                  <img src={productDetail && productDetail.product_images[0] && productDetail.product_images[0].image_name ? productDetail.product_images[0].image_name : businessDetail.logo} alt='' class="img-fluid w-10" />
                                </div>
                              </div>
                              <div class="col-9">
                                <div class="">
                                  <h5 class="heading-titlenew black-color mb-0"> {productDetail && productDetail.product_name} </h5>
                                  {productDetail && productDetail.product_description.length <= 40 &&
                                    <p class="brand-color3 font-12 my-2">{productDetail.product_description}</p>}
                                  {productDetail && productDetail.product_description.length > 40 &&
                                    <p class="brand-color3 font-12 my-2 paratitle">
                                      {productDetail.product_description} </p>}

                                  <h5 class="heading-titlenew brand-color3 mb-0">Amount : {businessDetail.currency} {productDetail && productDetail.product_price} </h5>
                                  {productDetail &&
                                    <div class="pt-1 w-50">
                                      <div id="input_div" class="counter-box d-flex align-items-center">

                                        <input type="button" value="+" id="plus"
                                          class="brand-color2 font-w-600 font-28 mr-1 functionplus"
                                          onClick={() => updateCount('+', productDetail)}
                                          style={{ fontFamily: "Arial" }} />

                                        <input type="text" id="count" class="inputWidth brand-color1 flex-fill" value={productCount}
                                          name="ProductCount" />
                                        <input type="button" value="-" id="moins"
                                          class="brand-color2 font-w-600 font-28 functionminus"
                                          onClick={() => updateCount('-', productDetail)}
                                          style={{ fontFamily: "Arial" }} />
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!paymentval &&
                    <div class="col-12 col-lg-6 mt-5 mt-lg-0">
                      <div class="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
                        <div class="px-3 py-3 text-left light-bg border-t-lr-6">
                          <h5 class="title black-color font-w-100 text-uppercase font-16 mb-0"> payment details </h5>
                        </div>
                        <div class="px-3 pt-4 pb-2 border-bottom">
                          <div class="row mb-2">
                            <div class="col-6 text-left">
                              <p class="black-color font-w-100 font-18"> Price(ProductCount Item) </p>
                            </div>
                            <div class="col-6 text-right">
                              <p class="black-color font-w-100 font-18"> {businessDetail.currency} {productDetail && productDetail.product_price} </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6 text-left">
                              <p class="black-color font-w-100 font-18"> Tax 1 </p>
                            </div>
                            <div class="col-6 text-right">
                              <p class="black-color font-w-100 font-18"> {businessDetail.currency} {productDetail && productDetail.tax1_rate} </p>
                            </div>
                            <div class="col-6 text-left">
                              <p class="black-color font-w-100 font-18"> Tax 2 </p>
                            </div>
                            <div class="col-6 text-right">
                              <p class="black-color font-w-100 font-18"> {businessDetail.currency} {productDetail && productDetail.tax2_rate} </p>
                            </div>
                          </div>
                        </div>
                        <div class="px-3 pt-4 pb-3 border-bottom">
                          <div class="row mb-2">
                            <div class="col-6 text-left">
                              <p class="title black-color font-w-100 font-18 mb-0"> Amount Payable </p>
                            </div>
                            <div class="col-6 text-right">
                              <p class="title black-color font-w-100 font-18 mb-0"> {businessDetail.currency} {productDetail && productDetail.product_total_price} </p>
                            </div>
                          </div>
                        </div>
                        <div class="px-3 py-5 text-center">
                          <button data-toggle="modal" data-target="#clientCheckOut" type="button" onClick={() => setModalshow(true)}
                            class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5"> checkout </button>
                        </div>
                      </div>
                    </div>
                  }

                  {paymentval &&
                    <div class="col-12 col-lg-6 mt-5 mt-lg-0">
                      <div class="client-buy-process border-radius6 box-shadow1 white-bg" >
                        <div class="p-3 text-left light-bg border-t-lr-6">
                          <h5 class="title black-color font-w-100 text-uppercase font-16 mb-0"> credit card / debit card </h5>
                        </div>
                        <div class="px-3 pt-4 pb-3 border-bottom">
                          <div class="row mb-2">
                            <div class="col-6 text-left">
                              <p class="title black-color font-w-100 font-18 mb-0"> Amount Payable </p>
                            </div>
                            <div class="col-6 text-right">
                              <p class="title black-color font-w-100 font-18 mb-0"> {businessDetail.currency} {productDetail.product_total_price} </p>
                            </div>
                          </div>
                        </div>
                        <div class="client-payment-input border-radius6 box-shadow1 white-bg p-4">
                          <div class="row">
                            {customerData && customerData.map((cardsave) => (
                              <div class="col-12 mb-3">
                                {cardsave.length != 0 &&
                                  <div class="client-card-img white-bg border-radius6 box-shadow1">
                                    <label class="select-card select-card-img select-card-cvv mb-2">
                                      {(cardsave.card_type == 'VISA' || cardsave.card_type == 'visa') &&
                                        <img src="clientassets/img/visa_card.png" alt='' class="img-fluid payment_img" />}
                                      {cardsave.card_type == 'MC' &&
                                        <img src="clientassets/img/master_card.png" alt='' class="img-fluid payment_img" />}
                                      {cardsave.card_type == 'AM' &&
                                        <img src="clientassets/img/american_express.png" alt='' class="img-fluid payment_img" />}
                                      <p class="mb-0 font-12 dark-color mt-2">{cardsave.number} </p>
                                      <h5 class="mb-0 font-12 dark-color mt-2">Valid Till(MM/YYYY) </h5>
                                      <p class="mb-0 font-12 dark-color mt-2">{cardsave.expiry_month} / {cardsave.expiry_year}</p>

                                      <input type="radio" checked={cardsave.card_id == cardType} name="radio"
                                        id={cardsave.card_id}
                                        value={cardsave.card_id}
                                        // [(ngModel)]="cardType" [ngModelOptions]="{standalone: true}"
                                        onChange={(e) => getCardType(e)}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                    {cardType == cardsave.card_id &&
                                      <form >
                                        <div class="text-center d-flex flex-wrap">
                                          <button type="button" class="btn btn-blue-grad px-4 w-100 text-uppercase mb-0 mb-md-3 ml-5"
                                            onClick={() => savePayment(cardsave)}
                                          > Pay Now </button>
                                        </div>
                                      </form>
                                    }
                                  </div>
                                }
                              </div>
                            ))}
                            <div class="col-12 mb-3">
                              <div class="white-bg border-radius6 box-shadow1">
                                <label class="select-card"> New Card
                                  <input type="radio" checked={cardType == "new"} name="radio" id="new" value="new"
                                    onChange={(e) => getCardType(e)}
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {cardType == 'new' &&
                          <form>
                            <div class="row py-4 px-3">
                              {/* Card Name */}
                              <div className="col-12 col-sm-6">
                                <div className="form-group">
                                  <p className="brand-color3 text-left font-14 mb-2"> Name On Card </p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    name='name'
                                    placeholder="Sophie Joe"
                                    value={cardData.name}
                                    onChange={CardInputChange}
                                  />
                                  <small className="text-danger">{carderror.name}</small>
                                </div>
                              </div>
                              {/* <!-- Card Number --> */}
                              <div className="col-12 col-sm-6">
                                <div className="form-group client-buy-input-cardno">
                                  <p className="brand-color3 text-left font-14 mb-2"> Card Number </p>
                                  <input
                                    type="text"
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder="4121 4411 3131 8554"
                                    name='crnumber'
                                    value={cardData.crnumber}
                                    onChange={CardInputChange}
                                    maxLength={19}
                                  />
                                  <small className="text-danger">{carderror.crnumber}</small>
                                </div>
                              </div>

                              {/* <!-- Exp... Dates --> */}
                              <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                <div className="form-group">
                                  <p className="brand-color3 text-left font-14 mb-2"> Valid Till </p>
                                  <input
                                    type="text"
                                    name={'dates'}
                                    className="form-control"
                                    placeholder="MM/YYYY"
                                    value={expiryDate}
                                    onChange={handleInputChange}
                                    pattern="^[0-9]{2}/[0-9]{4}"
                                    autoComplete="new-password"
                                    lable={"Valid Through"}
                                  />
                                  <small className="text-danger">{carderror.dates}</small>
                                </div>
                              </div>
                              {/* <!-- CVV  --> */}
                              <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                <div className="form-group">
                                  <p className="brand-color3 text-left font-14 mb-2"> CVV </p>
                                  <input
                                    type="password"
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder="eg. 201"
                                    name='cvv'
                                    value={cardData.cvv}
                                    onChange={CardInputChange}
                                    maxLength={3}
                                  />
                                  <small className="text-danger">{carderror.cvv}</small>
                                </div>
                              </div>
                              <div class="col-12">
                                <input type="checkbox" checked={savecard === 1} onChange={(e) => SavecardHandeler(e)} /> Save Card
                              </div>
                              <div class="w-100 py-5 text-center">
                                <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5"
                                  onClick={() => paymentHandler()}
                                > Proceed to pay </button>
                              </div>
                              {/* <!-- end of button and view policy --> */}

                            </div>
                          </form>
                        }
                      </div>
                    </div>
                  }
                  {/* <!-- end of col-md-6 --> */}
                </div>
                {/* <!-- end of row --> */}
              </div>
              {/* <!-- end of s-d-appoint-block --> */}
            </div>
            {/* <!-- end of s-d-appoint --> */}

          </div>
          {/* <!-- end of container --> */}
        </div>
        {/* <!-- End of My Studio Card Detials-Service Section --> */}


        {/* <!-- CHECKOUT MODAL POPUP --> */}
        <Modal show={modalshow} class="modal fade" >

          <div class="modal-body border-radius6 box-shadow1 p-5 white-bg" >
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModalshow(false)}>
              <span aria-hidden="true" style={{ fontSize: '40px', marginRight: '10px' }}>&times;</span> {/* This is the close icon */}
            </button>
            <div class="border-radius6 box-shadow-blue blue-gradient py-5 px-4 text-center">
              <img src="clientassets/img/logo_white.png" alt='' class="img-fluid" />
              <h4 class="title white-color text-uppercase my-4 font-w-600"> select checkout <br /> option </h4>
              {businessDetail.isCloverPurchase &&
                <button data-dismiss="modal" type="button" class="btn btn-white2 text-uppercase w-100 mb-4"
                  onClick={() => { setModalshow(false); setPaymentval(true); }}
                > pay now </button>}
              <button data-dismiss="modal" type="button" class="btn btn-white2 text-uppercase w-100"
                onClick={() => { setModalshow(false); PayAtStudio(); }}
              > pay on pick up </button>
            </div>
          </div>
        </Modal>
        {/* <!-- END OF CHECKOUT MODAL POPUP --> */}
      </div>
      <Innerfooter />

    </>
  )
}

import React, { useEffect, useState } from 'react'
import { StaticsHeader } from '../../Components/header/StaticsHeader'
import { Link, useNavigate } from 'react-router-dom'
import PlaceAutoCompleted from '../../Components/CommanPages/PlaceAutoCompleted';
import { InputField } from '../../../sharedComponents/InputField';
import { api_register_studio, staff_get_countries } from '../../../Static_Services/Staff_Services';
import { Spinner } from 'react-bootstrap';
import { successAlert } from '../../../Static_Services/Alertmsg';

export const ClientOtherDetail = () => {
  const [registerData, setRegisterData] = useState();
  const [otherData, setOtherData] = useState();
  const [countryList, setCountryList] = useState([]);
  const [loder, setLoder] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('w_clientregistration')) {
      setRegisterData(JSON.parse(localStorage.getItem('w_clientregistration')));
    }

    if (localStorage.getItem('w_clientOther')) {
      setOtherData(JSON.parse(localStorage.getItem('w_clientOther')));
    }
    getCountry();
  }, [])
  const [cd, setCd] = useState('');

  const getCountry = async () => {
    const response = await staff_get_countries();
    if (response) {
      const foundCountry = response.data.data.find(el => el.name === 'United States');
      if (foundCountry) {
        setCd(`${foundCountry.name} (${foundCountry.code})`);
      }
      setCountryList(response.data.data);

      //  this.otherData.emergency_country_code = cd;
    }

  }



  const [cordinates, setCordinates] = useState();
  const [error, setError] = useState({ date_of_birth: '', address: '', referred_by: '', emg_cont_parson: '', emg_cont_number: '', emg_country_code: '' })
  const [input, setInput] = useState({ radio: 'male', date_of_birth: '', address: '', city: '', state: '', country: '', zipcode: '', street: '', referred_by: '', emg_cont_parson: '', lattitude: '', longitude: '', emg_cont_number: '', emg_country_code: '' });
  
  
  useEffect(() => {
    
    if (cordinates && cordinates.getAddress) { setInput((pre) => ({ ...pre, address: cordinates.getAddress })); setError((pre) => ({ ...pre, address: '' })) }
    if (cordinates && cordinates.getCity) { setInput((pre) => ({ ...pre, city: cordinates.getCity })); }
    if (cordinates && cordinates.getState) { setInput((pre) => ({ ...pre, state: cordinates.getState })); }
    if (cordinates && cordinates.getCountry) { setInput((pre) => ({ ...pre, country: cordinates.getCountry })); }
    if (cordinates && cordinates.getZIP_Code) { setInput((pre) => ({ ...pre, zipcode: cordinates.getZIP_Code })); }
    if (cordinates && cordinates.getLocation_name) { setInput((pre) => ({ ...pre, street: cordinates.getLocation_name })); }
    if (cordinates && cordinates.getLat) { setInput((pre) => ({ ...pre, lattitude: cordinates.getLat })); }
    if (cordinates && cordinates.getLng) { setInput((pre) => ({ ...pre, longitude: cordinates.getLng })); }
  }, [cordinates]);

  const onchengeHandler = (e) => {
    const { name, value } = e.target;
    setInput((pre) => ({ ...pre, [name]: value }));
    setError((pre) => ({ ...pre, [name]: '' }));
  }

  const onregister1 = async () => {
    let isValid = 1;

    let newZipCode=123456;

    if (!input.address) { isValid = 2; setError((pre) => ({ ...pre, address: 'Address is required' })) }
    if (!input.date_of_birth) { isValid = 2; setError((pre) => ({ ...pre, date_of_birth: 'Date of Birth is required' })) }
    // if (!input.referred_by) { isValid = 2; setError((pre) => ({ ...pre, referred_by: 'Referred BY is required' })) }
    if (!input.emg_cont_parson) { isValid = 2; setError((pre) => ({ ...pre, emg_cont_parson: 'Emergency Contact Person is required' })) }
    if (!input.emg_cont_number) { isValid = 2; setError((pre) => ({ ...pre, emg_cont_number: 'Emergency Contact Number is required' })) }
    if (!input.emg_country_code) { isValid = 2; setError((pre) => ({ ...pre, emg_country_code: 'Please Select Country Code' })) }

    

    if (isValid === 1) {      
      setLoder(true);
      var formData = new FormData();
      formData.append('email', registerData.email);
      formData.append('mobile', registerData.mobile);
      formData.append('password', registerData.password);
      formData.append('name', registerData.name);
      formData.append('lastname', registerData.lastname);

      formData.append('gender', input.radio);
      formData.append('date_of_birth', input.date_of_birth);
      formData.append('address', input.address);
      formData.append('city', input.city);
      formData.append('state', input.state);
      formData.append('country', input.country);
      formData.append('about', '');
      formData.append('zipcode', input.zipcode);

      formData.append('lat', input.lattitude);
      formData.append('lang', input.longitude);
      formData.append('street', input.street);

      formData.append('role', '3');
      formData.append('image', '');
      formData.append('device_type', '');
      formData.append('device_token', '');
      // formData.append('country_code', registerData.register_courtycode);
      formData.append('country_code',input.emg_country_code);
      formData.append('referred_by', '');
      formData.append('emergency_contact_person', input.emg_cont_parson);
      formData.append('emergency_country_code', input.emg_country_code);
      formData.append('emergency_contact_no', input.emg_cont_number);
      formData.append('singup_for', registerData.singup_for);
      const response = await api_register_studio(formData);
      if (response) {
        setLoder(false);
        localStorage.setItem('w_UserID', JSON.stringify(response.data.data.id));
        successAlert(response.data.message);
        navigate('/signin');
        localStorage.removeItem('w_clientOther');
        localStorage.removeItem('w_clientregistration');
      }
      setLoder(false);
    }
    //   var url = 'superadmin/webservices/api/register';
    //   this.commonservice.PostApis(formData,url).subscribe((response : any) =>{
    //   if(response.status == 1){
    //     this.register1Spinner = false;
    //     localStorage.setItem('w_UserID',<any> JSON.stringify(response.data.id));
    //     var userSubject = response.data;
    //     this.registerMessage = response.message; 
    //    this.route.navigate(['/signin']);

    //     localStorage.removeItem('w_clientOther');
    //     localStorage.removeItem('w_clientregistration');
    //     this.commonservice.successAlert(response.message);
    //   }
    //   else{
    //     localStorage.setItem('w_clientOther', <any>JSON.stringify(userdetail.value));
    //     this.register1Spinner = false;
    //     this.registerMessage = response.message; 
    //     this.commonservice.dangerAlert(response.message);
    //   }
    // })
    // } else{
    //   this.register1Spinner = false;
    //   return false;
    // }
  }

  return (
    <>
      {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}
      <StaticsHeader />
      <div className="light-bg2">

        {/* <!-- Login Content Section --> */}
        <div className="background-before">
          <div className="d-flex align-items-center justify-content-center staff-register-height p-4 p-sm-0">
            <div className="align-item-center text-center wow animated slideInDown">
              <div className="staff-login-box-owner white-bg box-shadow1 border-radius10 py-4 px-3 mt-2">

                <div className="login-header text-center px-5">
                  <h3 className="title dark-color mb-4"> Other Details </h3>
                </div>
                {/* <!-- end of login-header --> */}

                <div className="logn-form mb-2">
                  <form >
                    <div className="form-group profile-gender mb-2">
                      <p className="brand-color3 text-left font-14 mb-2"> Gender </p>
                      <div className="profile-gender-button2 text-left">
                        <input type='radio' id='male' value="male" onChange={onchengeHandler} checked={input.radio === 'male'} name='radio' />
                        <label for='male'>Male</label>

                        <input type='radio' id='female' value="female" onChange={onchengeHandler} checked={input.radio === 'female'} name='radio' />

                        <label for='female'>Female</label>
                        <input type='radio' id='other' value="other" onChange={onchengeHandler} checked={input.radio === 'other'} name='radio' />
                        <label for='other'>Other</label>
                      </div>
                      {/* <!-- end of profile-gender-button --> */}
                    </div>
                    {/* <!-- Gender -->   */}

                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> DOB </p>

                      <div className="invalid-feedback">
                        <div>Date of Birth is required</div>
                      </div>
                      <InputField type="date" name={'date_of_birth'} onChange={onchengeHandler} />
                      <small className="text-danger">{error.date_of_birth}</small>
                    </div>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Address </p>
                      <PlaceAutoCompleted setCordinates={setCordinates} />
                      <small className="text-danger">{error.address}</small>
                    </div>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Apartment, Street (optional) </p>
                      <input type="text" name='street' value={input.street} readOnly className="form-control-1 w-100" placeholder="" />
                    </div>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Country </p>
                      <input type="text" className="form-control-1 w-100" name='country' value={input.country} readOnly placeholder="" />
                      <div className="invalid-feedback">
                        <div>Country is required</div>
                      </div>
                    </div>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> State/Province </p>
                      <input type="text" className="form-control-1 w-100" placeholder="" name='state' value={input.state} readOnly />
                      <div className="invalid-feedback">
                        <div>State is required</div>
                      </div>
                    </div>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> City </p>
                      <input type="text" className="form-control-1 w-100" placeholder="" name='city' value={input.city} readOnly />
                      <div className="invalid-feedback">
                        <div>City is required</div>
                      </div>
                    </div>


                    {/* <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Referred BY</p>
                      <input type="text" className="form-control-1 w-100" placeholder="" name='referred_by' onChange={onchengeHandler} />
                      <small className="text-danger">{error.referred_by}</small>                   
                    </div> */}

                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Emergency Contact Person </p>
                      <input type="text" className="form-control-1 w-100" placeholder="" name='emg_cont_parson' onChange={onchengeHandler} />
                      <small className="text-danger">{error.emg_cont_parson}</small>                     
                    </div>


                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2">Emergency Country Code </p>
                      <select className="form-control-1 w-100" placeholder="Carl" name='emg_country_code' onChange={onchengeHandler} >
                        <option value=""></option>
                        {countryList &&
                          countryList.length > 0 &&
                          countryList.map((code, index) => (
                            <option key={index} value={code.code}>
                              {code.name} ({code.code})
                            </option>
                          ))}
                      </select>
                      <small className="text-danger">{error.emg_country_code}</small>
                      {/* <div className="invalid-feedback">
                        <div>Please Select Country Code</div>
                      </div> */}
                    </div>

                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Emergency Contact Number </p>
                      <input type="number" className="form-control-1 w-100" placeholder="" name='emg_cont_number' onChange={onchengeHandler} />
                      <small className="text-danger">{error.emg_cont_number}</small>
                      <div className="invalid-feedback">
                        <div>Emergency Contact Number is required</div>
                        <div>Emergency Contact Number not valid</div>
                      </div>
                    </div>
                    <div className="py-3">
                      <button type="button" className="btn btn-blue-grad text-uppercase w-100" onClick={onregister1} >
                        {/* <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> */}
                        Next </button>
                      <button type="button" className="btn btn-green-grad text-uppercase w-100 mt-2"> Back </button>

                    </div>
                    <div className="text-center">
                      <Link to="/terms-conditions" className="brand-color1 font-14"> Terms & Conditions </Link>
                    </div>
                  </form>
                </div>
                {/* <!-- end of logn-form --> */}

              </div>
              {/* <!-- end of login-box --> */}
            </div>
          </div>
          {/* <!-- end of d-flex --> */}
        </div>
        {/* <!-- End of About Content Section --> */}
      </div>
    </>
  )
}

import React, { useEffect } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { InputField } from "../../../../../sharedComponents/InputField";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { Row, Col, Container, Form } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { get_business_categories, update_class } from "../../../../../Studio-Services/Studio_Services";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { errorAlert } from "../../../../../Static_Services/Alertmsg";
import { classStatusOptions } from "../../../../../sharedComponents/Helper";



export const Editclass = () => {
  const location = useLocation();
  const [details, setDetails] = useState({ "class_name": '', "class_type": '', "duration": '', "description": '', 'class_type_id': '', 'class_id': "", "status": "" });


  const preData = location.state.details;
  // const [edescription, setEdescription] = useState();
  const [error, setError] = useState({ "class_name": '', "class_type": '', "duration": '', "description": '', "status": "" })
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);

  const getBusinessCategories = async () => {
    const fData = { "type": "1", "subcat": "" }
    const type = [];
    const resp = await get_business_categories(fData);
    if (resp) {
      const fresp = resp.data.data;
      fresp.map((e) => (
        type.push({
          value: e.category_id, label: e.category_name
        })
      ))
      setCategory(type);
    }
  }

  useEffect(() => {
    getBusinessCategories();
    const pre = { ...details };
    if (preData?.class_id) pre.class_id = preData?.class_id;
    if (preData?.class_name) pre.class_name = preData?.class_name;
    if (preData?.class_type_id) pre.class_type_id = preData?.class_type_id;
    if (preData?.class_type) pre.class_type = preData?.class_type;
    if (preData?.duration) pre.duration = preData?.duration;
    if (preData?.description) pre.description = preData?.description;
    if (preData.status) pre.status = preData.status;
    setDetails(pre);
  }, [preData])

  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const formData = new FormData(e.target);
    const formValues = Object.fromEntries(formData.entries());

    const class_id = details?.class_id;
    if (!class_id) { errorAlert("class id required"); return; }
    const class_name = details?.class_name;
    const class_type = details?.class_type
    const class_type_id = details?.class_type_id;
    const duration = details?.duration;
    const description = details?.description;
    const status = details?.status;
    if (!class_name) { setError((pre) => ({ ...pre, class_name: '*required' })); isValid = 0; }
    if (!class_type_id) { setError((pre) => ({ ...pre, class_type: '*required' })); isValid = 0; }
    if (!duration) { setError((pre) => ({ ...pre, duration: '*required' })); isValid = 0; }
    if (!description) { setError((pre) => ({ ...pre, description: '*required' })); isValid = 0; }

    if (isValid === 1) {
      const finalData = {
        "class_name": class_name,
        "class_type": class_type,
        "class_type_id": class_type_id,
        "duration": duration,
        "description": description,
        "class_id": class_id,
        "status": status
      }
      Swal.fire({
        html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <p class="title text-uppercase text-center mt-4 textWhite">Saving these changes will change details on all past and future records.</p>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">Are you sure to change status ?</small>
        </p>
      `,
        showClass: {
          popup: "custom-dialog-container",
        },
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "YES",
        cancelButtonText: "NO",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resp = await update_class(finalData);
          if (resp) {
            Swal.fire({
              html: `
            <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
            <h4 class="title text-uppercase text-center mt-4 textWhite">
              SUCCESS
            </h4>
            <p class="text-center my-4 textcolor">
              <small class="textWhite">
                ${resp.data.message}
              </small>
            </p>
          `,
              showClass: {
                popup: 'custom-dialog-container',
              },
              customClass: {
                confirmButton: 'custom-button custom-button-size modalbtn',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/list-class");
              }
            });

          }
        }
      })
    }
  }

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setDetails((pre) => ({ ...pre, [name]: value }));
    setError((pre) => ({ ...pre, [name]: '' }));
  }


  const categoryHandler = (data) => {
    setDetails((pre) => ({
      ...pre,
      class_type_id: data,
    }))
  }
  return (
    <div className="MainDashboard">
      <div className="MainLayout">
        <Row>
          <Sidebar />
          <Col className="p-0 RightCol">
            <div className="RightSide">
              <HeaderDashboard />
              <div className="innerContent">
                <Container>
                  <h3 className="my-2">Class's Detail</h3>
                  <section className="editClass">
                    <Form onSubmit={submitHandler}>
                      <Row>
                        <InputField name="class_id" value={details.class_id} type={"hidden"} />
                        <Col md={6} >
                          <InputField lable={"Name"} name="class_name" onChange={onChangeHandler} value={details && details.class_name ? details.class_name : ""} placeholder={"All day open gym"} type={"text"} error={error.class_name} />
                        </Col>
                        <Col md={6}>
                          <InputField lable={"Duration (In Minutes)"} onChange={onChangeHandler} name="duration" value={details && details.duration ? details.duration : ""} placeholder={"200"} type={"number"} error={error.duration} />
                        </Col>
                        <Col md={6}>
                          <label className="form-label inputLable">Type</label>
                          <select className="form-select" name="class_type" onChange={(e) => categoryHandler(e.target.value)} value={details?.class_type_id} >
                            {category && category.map((code, i) => (
                              <option key={i} value={code.value}>{code.label}  </option>
                            ))}
                          </select>
                          <small className='text-danger'>{error.class_type}</small>


                          {/* <InputField lable={"Type"} name="class_type" readOnly={'readOnly'} value={details && details.class_type ? details.class_type : ""} placeholder={"Other"} type={"text"} error={error.class_type} /> */}
                        </Col>
                        <Col md={6}>
                          <label className="form-label inputLable">Status</label>
                          <select className="form-select" name="status" onChange={onChangeHandler} value={details?.status} >
                            {classStatusOptions && classStatusOptions.map((code, i) => (
                              <option key={i} value={code.value}>{code.label}  </option>
                            ))}
                          </select>
                          <small className='text-danger'>{error.class_type}</small>
                        </Col>

                      </Row>
                      <Row>
                        <Col lg={12} sm={12}>
                          <TextArea lable={"Description"} name="description" onChange={onChangeHandler} value={details && details.description ? details.description : ""} error={error.description} />
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col lg={4} sm={12}>
                          <SharedButton title={"UPDATE"} type={"submit"} variant={"primary"} className={"btn btn-primary w-100"} />
                        </Col>
                        <Col lg={4} sm={12}>
                          <SharedButton title={"CANCEL"} type={"submit"} variant={"outline"} className={"btn btn-outline w-100"} />
                        </Col>
                      </Row>
                    </Form>
                  </section>
                </Container>
              </div>
              <Footer />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
